import React from 'react'
import { components } from 'react-select'
import ArrowDownSelect from '../icons/ArrowDownSelect'
import TypeField from './TypeField'

const SortByControl = ({ children, ...props }) => (
  <components.Control {...props} className="px-3 py-0.5">
    <span className="font-bold">Ordenar:</span> {children}
  </components.Control>
)

const SortMobileField = ({ value, onChange, options, disabled = false }) => {
  return (
    <TypeField
      variant="horizontal"
      className="sm:hidden min-w-15"
      disabled={disabled}
      value={value}
      isMulti={false}
      onChange={value => onChange(value)}
      options={options}
      isClearable={false}
      components={{ Control: SortByControl, DropdownIndicator: ArrowDownSelect }}
    />
  )
}

export default SortMobileField
