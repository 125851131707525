import React from 'react'
import RSSIcon from '../icons/RSSIcon'

const RSSButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="flex flex-col md:flex-row text-blue-700 underline font-medium whitespace-no-wrap md:items-center text-sm md:text-base"
    >
      <RSSIcon /> Canal RSS
    </button>
  )
}

export default RSSButton
