import React, { Component } from 'react'

export default class Checkbox extends Component {
  render() {
    const { label, className, disabled, count, ...props } = this.props
    return (
      <div className="flex">
        <label className={'checkbox flex-1 ' + className}>
          <input disabled={disabled && !props.checked} type="checkbox" {...props} />
          <span />
          {label}
        </label>
        {count != undefined ? `(${count ? count.toLocaleString('en') : count})` : ''}
      </div>
    )
  }
}
