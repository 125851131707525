import React, { createContext, useState } from 'react'

export const TabsContext = createContext(null)

const Tabs = ({ children, onChange, value }) => {
  const handleChange = value => {
    onChange(value)
  }

  const length = React.Children.count(children)

  return (
    <ul className="grid grid-flow-col auto-cols-fr bg-blue-100 mb-0 search_tabs rounded-lg">
      <TabsContext.Provider value={{ currentValue: value, onClick: handleChange, length }}>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            index
          })
        })}
      </TabsContext.Provider>
    </ul>
  )
}

export default Tabs
