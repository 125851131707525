import React, { Fragment, useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import QuestionIcon from '../icons/QuestionIcon'

const Help = ({ setOpenAndScrollSearchFeedback }) => {
  const containerRef = useRef(null)
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)
  const [show, setShow] = useState(false)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, 12] } }
    ]
  })
  useEffect(() => {
    if (show) {
      const clickOff = evt => {
        if (!containerRef.current.contains(evt.target)) {
          document.removeEventListener('click', clickOff)
          setShow(false)
        }
      }
      document.addEventListener('click', clickOff)
      return () => document.removeEventListener('click', clickOff)
    }
  }, [show])
  const handleFeedbackOpen = e => {
    e.preventDefault()
    setOpenAndScrollSearchFeedback(true)
  }
  return (
    <div ref={containerRef} className="hidden md:block">
      <button
        className="flex items-center gap-2 ml-auto rounded-md bg-white p-2"
        ref={setReferenceElement}
        onClick={() => setShow(show => !show)}
      >
        <QuestionIcon />
        <span className="text-blue-375 font-medium">Ayuda para tu búsqueda</span>
      </button>
      {show && (
        <div
          className="bg-indigo-900 text-white p-9 search-help z-20 rounded-9px max-w-24"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <p className="font-medium">Si no encuentras lo que buscas, prueba estas opciones:</p>
          <ul className="list-inside leading-8 mb-0">
            <li className="list-disc">
              <a href="#" onClick={handleFeedbackOpen} className="text-white hover:text-white underline">
                Reportar falta de información en Gob.pe
              </a>
            </li>
            <li className="list-disc">
              <a href="/busquedas" className="text-white hover:text-white underline">
                Ir al buscador general
              </a>
            </li>
          </ul>
          <div className="search-help__arrow w-4 h-4 bg-indigo-900" ref={setArrowElement} style={styles.arrow} />
        </div>
      )}
    </div>
  )
}

export default Help
