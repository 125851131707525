import React, { Fragment } from 'react'
import NoResultsFiltersIcon from '../icons/NoResultsFiltersIcon'
import NoResutsTermIcon from '../icons/NoResultsTermIcon'
import { useFilters } from './FiltersProvider'

const NoResults = ({ term }) => {
  const { removeAllFiltersAndApply } = useFilters()
  const handleRemoveFilters = e => {
    e.preventDefault()
    removeAllFiltersAndApply()
  }
  return (
    <div className="flex flex-col items-center py-12 max-w-2xl mx-auto text-center">
      {term ? (
        <Fragment>
          <NoResutsTermIcon className="w-full max-w-xl" />
          <h2 className="text-2xl mb-4">No se encontraron resultados para "{term}"</h2>
          <p>Asegúrate de que las palabras estén escritas correctamente o prueba ingresando otras palabras claves.</p>
        </Fragment>
      ) : (
        <Fragment>
          <NoResultsFiltersIcon className="w-full max-w-xl" />
          <h2 className="text-2xl mb-4 mt-8">No encontramos resultados</h2>
          <p>Parece que aplicaste muchos filtros. Intenta quitando algunos para obtener resultados.</p>
          <a className="font-medium underline" href="#" onClick={handleRemoveFilters}>
            Borrar todos los filtros
          </a>
        </Fragment>
      )}
    </div>
  )
}

export default NoResults
