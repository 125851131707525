import React, { useRef } from 'react'
import ClippyIcon from '../icons/ClippyIcon'
import { copyToClipboard } from './helpers'

const RSSHelper = ({ show }) => {
  const textRef = useRef(null)

  if (!show) return null

  return (
    <div>
      <div className="flex flex-wrap items-center bg-blue-200 mb-4">
        <div className="py-2 pl-3 pr-2 text-sm whitespace-pre">Copia y pega el siguiente link en tu lector de RSS</div>
        <div className="clipboard flex p-2 flex-1 leading-none">
          <input
            type="text"
            ref={textRef}
            name="term"
            id="term"
            value={`https://www.gob.pe/busquedas.rss${window.location.search}`}
            size="25"
            className="flex-1 text-sm mr-2 border-none text-blue-550 bg-blue-100"
            readOnly="readonly"
          />
          {document.queryCommandSupported('copy') && (
            <button
              className="bg-white hover:bg-gray-40 py-2 pr-2 pl-3 text-blue-700 flex whitespace-pre items-center text-sm shadow font-medium"
              onClick={e => copyToClipboard(e, textRef)}
              title="Copiar"
            >
              Copiar enlace
              <ClippyIcon className="ml-2" />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default RSSHelper
