import omitBy from 'lodash/omitBy'
import React from 'react'
import XIcon from '../icons/XIcon'
import { useFilters } from './FiltersProvider'

const getItem = (values = [], collection = []) => {
  if (!values) return null
  if (values.constructor === Array) {
    return values.map(value => collection.find(c => c.value === value) || '')
  } else {
    return collection.find(c => c.value === values) || ''
  }
}

const contenido_map = {
  servicios: 'Orientación, trámites y servicios',
  normas: 'Normas y documentos legales',
  noticias: 'Noticias',
  campañas: 'Campañas',
  instituciones: 'Portales institucionales',
  institucional: 'Contenido institucional',
  servidores: 'Funcionarios',
  publicaciones: 'Informes y publicaciones'
}

const Tag = ({ onClick, label }) => {
  return (
    <li className="bg-white border border-gray-400 shadow-md px-2 py-0.5 text-xs mr-4 mb-2 flex items-center">
      {label}
      <button className="ml-4" onClick={onClick}>
        <XIcon />
      </button>
    </li>
  )
}

const Tags = ({ filterOptions }) => {
  const { state, send } = useFilters()
  const validFilters = omitBy(state.context.values, v => v?.length === 0 || !v)
  const validKeyFilters = Object.keys(validFilters)
  if (!validKeyFilters.length) return null

  const labels = validKeyFilters
    .map(key => {
      const value = validFilters[key]
      const labelMap = {
        desde: `Desde ${value}`,
        hasta: `Hasta ${value}`,
        institucion: getItem(value, filterOptions.institutions),
        categoria: getItem(value, filterOptions.categories),
        tipo_noticia: getItem(value, filterOptions.feed_types),
        tipo_norma: getItem(value, filterOptions.group_rule_types),
        tipo_publicacion: getItem(value, filterOptions.group_report_types),
        tipo_segmento: getItem(value, filterOptions.segments),
        only_collections_rules: 'Solo compendios',
        only_collections_reports: 'Solo compendios',
        only_collections_feeds: 'Solo compendios',
        add_collections_rules: 'Compendios',
        add_collections_reports: 'Compendios',
        add_collections_feeds: 'Compendios'
      }
      const label = contenido_map[key] || labelMap[key]
      const items = Array.isArray(label) ? label : [label]

      let onClick

      return items.map(item => {
        if (
          ['tipo_publicacion', 'tipo_norma', 'tipo_noticia', 'tipo_segmento', 'categoria', 'institucion'].includes(key)
        ) {
          onClick = () =>
            send('CHANGE_SUBMIT', {
              key,
              value: (Array.isArray(value) ? value : [value])?.filter(v => v !== item.value)
            })
        } else {
          onClick = () =>
            send('CHANGE_SUBMIT', {
              key,
              value: false
            })
        }
        const label = item?.label || item
        if (!label) return null
        return { label, onClick }
      })
    })
    .flat()
    .filter(Boolean)
  if (labels.length === 0) return null
  return (
    <ul className="mt-6 mb-0 flex flex-wrap items-baseline">
      <span className="font-bold mr-4 text-sm">Filtros aplicados: </span>
      {labels.map(({ label, onClick }) => (
        <Tag key={label} label={label} onClick={onClick} />
      ))}
    </ul>
  )
}

export default Tags
