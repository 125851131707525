import React from 'react'
import XIcon from '../icons/XIcon'

const Tag = ({ onRemove, label }) => {
  if (!label) return null
  if (label === 'Todos') return null
  return (
    <li className="bg-white border border-gray-140 shadow-tag px-2 py-1 text-xs flex gap-2 items-center font-medium">
      {label}
      <button className="text-blue-700" onClick={onRemove}>
        <XIcon />
      </button>
    </li>
  )
}
export default Tag
