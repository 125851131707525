import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

const Modal = ({ children, onRequestClose, variant, show, ...props }) => {
  const classes = variant === 'institutional' ? 'fixed h-screen w-screen overflow-y-auto z-100 top-0 pb-12' : ''
  const el = document.querySelector('.react-modal')
  const mainElement = document.querySelector('main')
  useEffect(() => {
    if (show) mainElement.classList.add('hidden')
    return () => {
      mainElement.classList.remove('hidden')
      window.scrollTo(0, 0)
    }
  }, [show])
  return show
    ? ReactDOM.createPortal(
        <div className={classes + ' bg-white modal-opened'} {...props}>
          {children}
        </div>,
        el
      )
    : null
}

export default Modal
