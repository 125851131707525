import React from 'react'

const ClippyIcon = ({ ...props }) => {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20 1.88867H7V16.9998H20V1.88867ZM18 15.1109H9V3.77756H18V15.1109ZM3 14.1664V12.2776H5V14.1664H3ZM3 8.972H5V10.8609H3V8.972ZM10 18.8887H12V20.7776H10V18.8887ZM3 17.472V15.5831H5V17.472H3ZM5 20.7776H3V18.8887H5V20.7776ZM8.5 20.7776H6.5V18.8887H8.5V20.7776ZM15.5 20.7776H13.5V18.8887H15.5V20.7776ZM3 5.66645H5V7.55534H3V5.66645Z"
        fill="#0056AC"
      />
    </svg>
  )
}

export default ClippyIcon
