import React, { Fragment } from 'react'
import FilterIcon from '../icons/FilterIcon'
import Modal from '../Modal'
import { useFilters } from './FiltersProvider'

const ModalFilters = ({ children }) => {
  const { state, send } = useFilters()

  const open = state.matches('mobile.opened')
  const isMobile = state.matches('mobile')
  return (
    <Fragment>
      {isMobile ? (
        <Modal show={open} onRequestClose={() => send('CANCEL')}>
          <div className="p-4">{children}</div>
        </Modal>
      ) : (
        children
      )}
      {isMobile && (
        <button className="flex text-blue-700 font-bold items-center mb-4" onClick={() => send('OPEN')}>
          <div className="mr-2">
            <FilterIcon />
          </div>
          <span>Otros filtros de búsqueda</span>
        </button>
      )}
    </Fragment>
  )
}

export default ModalFilters
