import React, { useEffect } from 'react'
import PaginationLibrary from 'react-paginate'
import ArrowRight from '../icons/ArrowRight'
import { useFilters } from './FiltersProvider'

const Pagination = ({ totalCount, ...props }) => {
  const { state, send } = useFilters()

  //quitamos el aria-label despues de ejecutar el componente (requisito de accesibilidad)
  useEffect(function () {
    const el = document.querySelectorAll('.element-page a')
    el.forEach(el => el.removeAttribute('aria-label'))
  })

  let range = 1 //el rango que que se muestra en el paginador (defecto mobile muestra 1)
  if (state.value == 'desktop') range = 4 // si es desktop se muestra 4

  //TODO: scrollear hacia arriba luego del submit
  return (
    <PaginationLibrary
      forcePage={Number(state.context.values.sheet) - 1}
      onPageChange={sheet => send('CHANGE_INSTA_SUBMIT', { key: 'sheet', value: sheet.selected + 1 })}
      previousAriaLabel={'Página anterior'}
      nextAriaLabel={'Siguiente página'}
      containerClassName={'pagination'}
      activeClassName={'active'}
      pageClassName={'element-page'}
      breakLabel={''}
      pageCount={Math.ceil(totalCount / 25)}
      marginPagesDisplayed={0}
      pageRangeDisplayed={range}
      previousLabel={
        <span className="flex items-center">
          <ArrowRight className="mr-2 transform rotate-180" aria-hidden="true" /> Anterior
        </span>
      }
      nextLabel={
        <span className="flex items-center">
          Siguiente <ArrowRight className="ml-2" aria-hidden="true" />
        </span>
      }
      {...props}
    />
  )
}

export default Pagination
