import React from 'react'
import DownloadIcon from '../icons/DownloadIcon'
import ExternalUrlIcon from '../icons/ExternalUrlIcon'
import Button from '../ui/Button'
import { confirmAlert } from 'react-confirm-alert'

const action_anchor_label_map = {
  service: 'Ir al servicio',
  procedure: 'Iniciar trámite',
  BranchOffice: 'Ver mapa'
}

export const confirmActionDialog = event => {
  const host_permited = ['www.gob.pe', 'localhost']

  if (host_permited.find(v => event.target.hostname.includes(v))) return

  const event_url = event.target.href
  event.preventDefault()

  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div
          role="dialog"
          aria-labelledby="dialog-confirm"
          aria-modal="true"
          className="box-dialog-url flex flex-row shadow-md md:mt-15 p-4 "
        >
          <div className="m-4 w-1/3">Estás a punto de salir de Gob.pe</div>
          <div className="flex flex-col w-2/3">
            <p className="m-2 font-bold text-center">¿Deseas continuar?</p>
            <div className="grid grid-cols-2 gap-4 mt-2">
              <button
                className="btn-modal btn--confirm"
                onClick={() => {
                  window.open(event_url, '_blank')
                  onClose()
                }}
              >
                Aceptar
              </button>
              <button className="btn-modal btn--cancel" onClick={onClose}>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )
    }
  })
}

const ActionLink = ({ item, className }) => {
  if (!item.action_url) return null

  if (action_anchor_label_map[item.page_kind || item.searchable_type]) {
    return (
      <Button
        variant="tertiary"
        className={'flex items-center w-fit ' + className}
        component="a"
        href={item.action_url}
        target="_blank"
        onClick={confirmActionDialog}
      >
        {action_anchor_label_map[item.page_kind || item.searchable_type]}
        <ExternalUrlIcon width="14px" height="14px" className="inline ml-8 text-blue-375" />
      </Button>
    )
  }

  if (item.page_kind) return null
  return (
    <Button
      variant="secondary"
      className={'flex gap-1 w-fit ' + className}
      component="a"
      href={item.action_url}
      target="_blank"
      onClick={confirmActionDialog}
    >
      <DownloadIcon width="24px" height="24px" className="inline" />
      Descargar
    </Button>
  )
}

export default ActionLink
