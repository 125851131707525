import React from 'react'
import { useFilters } from '../institution_searcher/FiltersProvider'
import { sortableContentTypes } from '../institution_searcher/helpers'
import TypeField from './TypeField'
import SortMobileField from './SortMobileField'

const SortField = () => {
  const { state, changeSortBy } = useFilters()
  const contentType = state.context.oldValues.contentType
  let options = [{ value: 'none', label: 'Más relevante' }]

  if (sortableContentTypes.includes(contentType)) {
    options.push({
      value: 'recent',
      label: 'Más recientes'
    })
    options.push({
      value: 'older',
      label: 'Más antiguos'
    })
  }

  return (
    <>
      <TypeField
        label="Ordenar por:"
        variant="horizontal"
        className="max-w-20 w-full hidden sm:flex"
        placeholder="Más relevante"
        disabled={!sortableContentTypes.includes(contentType)}
        value={state.context.oldValues.sort_by}
        isMulti={false}
        onChange={value => changeSortBy(value)}
        options={options}
        isClearable={false}
      />
      <SortMobileField
        value={state.context.oldValues.sort_by}
        onChange={value => changeSortBy(value)}
        options={options}
        disabled={!sortableContentTypes.includes(contentType)}
      />
    </>
  )
}

export default SortField
