import React, { Fragment } from 'react'
import TrashIcon from '../icons/TrashIcon'
import { useFilters } from './FiltersProvider'
import { apiToKeysMap } from './helpers'
import Tag from './Tag'

const CurrentFilters = ({ availableOptions }) => {
  const { state, removeFilter, removeAllFiltersAndApply } = useFilters()
  const { desde, hasta } = state.context.oldValues

  const subtypesTags = Object.keys(apiToKeysMap)
    .map(urlKey => ({
      label: availableOptions[apiToKeysMap[urlKey]]?.find(c => c.value === state.context.oldValues[urlKey])?.label,
      value: urlKey
    }))
    .filter(t => t.label)

  let dateTags = []
  if (desde) {
    dateTags.push({
      label: `Desde: ${desde}`,
      value: 'desde'
    })
  }
  if (hasta) {
    dateTags.push({
      label: `Hasta: ${hasta}`,
      value: 'hasta'
    })
  }

  const tags = [...subtypesTags, ...dateTags].filter(c => c.label)

  if (tags.length === 0) return null

  const handleRemoveFilters = e => {
    e.preventDefault()
    removeAllFiltersAndApply()
  }

  return (
    <Fragment>
      <section
        aria-labelledby="applied-filters-label"
        className="container md:max-w-6xl mx-auto flex gap-4 py-6 hidden md:block"
      >
        <span className="font-medium whitespace-pre" id="applied-filters-label">
          Filtros aplicados:
        </span>
        <ul className="mb-0 flex flex-wrap items-center gap-4">
          {tags.map(({ label, value }) => (
            <Tag key={label} label={label} onRemove={() => removeFilter(value)} />
          ))}
          <li className="flex items-start">
            <a className="text-sm font-bold gap-1 flex items-center underline" href="#" onClick={handleRemoveFilters}>
              <TrashIcon /> Borrar todo
            </a>
          </li>
        </ul>
      </section>
      <div className="container md:max-w-6xl mx-auto">
        <hr className="my-0" />
      </div>
    </Fragment>
  )
}

export default CurrentFilters
