import React, { useLayoutEffect, useState } from 'react'

const FiltersTriangle = () => {
  const [trianglePosition, setTrianglePosition] = useState(0)
  useLayoutEffect(() => {
    const button = document.getElementById('js-filter-button')
    const { width, left } = button.getBoundingClientRect()
    setTrianglePosition(width / 2 + left - 16)
  }, [])
  return (
    <div className="absolute left-0" style={{ left: `${trianglePosition}px`, top: '-16px' }}>
      <div className="bg-white w-8 h-8 ml-auto mr-12 transform rotate-45" />
    </div>
  )
}

export default FiltersTriangle
