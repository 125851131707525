import React from 'react'

const SearchIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43" {...props}>
      <g>
        <g>
          <path
            fill="#33333d"
            d="M15.848 26.607a10.817 10.817 0 0 1-10.831-10.83A10.817 10.817 0 0 1 15.848 4.944a10.817 10.817 0 0 1 10.831 10.831 10.817 10.817 0 0 1-10.831 10.831zm14.442 0h-1.902l-.674-.65a15.576 15.576 0 0 0 3.78-10.18C31.493 7.134 24.488.13 15.847.13 7.207.13.203 7.135.203 15.776s7.004 15.645 15.645 15.645c3.875 0 7.438-1.42 10.182-3.779l.65.674v1.902l12.034 12.01 3.587-3.586z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SearchIcon
