import React, { useContext } from 'react'
import { FiltersContext } from './FiltersProvider'
import LinkIcon from '../icons/ArrowRight'

const LinkDownloadButton = ({ institutionReportZipEnabled }) => {
  const { state } = useContext(FiltersContext)
  const isNormasContentType = state.context.contentType === 'normas'
  const isZipEnabled = institutionReportZipEnabled === 'true'

  return (
    isNormasContentType &&
    isZipEnabled && (
      <a
        className="flex flex-row text-blue-700 underline font-medium whitespace-no-wrap items-center text-sm md:text-base underline font-medium"
        href="normas-legales/descarga"
      >
        Ver documentos por año <LinkIcon className="ml-3" />
      </a>
    )
  )
}

export default LinkDownloadButton
