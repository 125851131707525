import React, { useContext } from 'react'
import { TabsContext } from './Tabs'

const TabItem = ({ label, value, index, tabLabelProceduresAndServices }) => {
  const { currentValue, onClick, length } = useContext(TabsContext)
  const isSelected = currentValue === value
  const isLast = index === length - 1
  const classNames = isSelected ? 'text-indigo-900 bg-white shadow-tabItem font-medium' : ''
  const borderClassNames = isLast ? '' : ''
  const labelTab = index === 1 ? tabLabelProceduresAndServices : label
  return (
    <li className="flex items-center justify-center relative p-2">
      <button
        className={`py-3 px-2 w-full h-full text-sm rounded-lg leading-none ${classNames} ${borderClassNames}`}
        onClick={() => onClick(value)}
      >
        {labelTab}
      </button>
    </li>
  )
}

export default TabItem
