import React, { Fragment } from 'react'
import ErrorImage from '../icons/ErrorImage'
import FullArrowRightIcon from '../icons/FullArrowRightIcon'
import LightningIcon from '../icons/LightningIcon'
import QuestionIcon from '../icons/QuestionIcon'
import Loading from '../ui/Loading'
import { useFilters } from './FiltersProvider'
import Result from './Result'
import RSS from './RSS'
import SearchFeedback from './SearchFeedback'
import { SorterField } from './SorterField'
import LinkDownloadButton from './LinkDownloadButton'

const Results = ({ results, totalCount, searchId, error, loading, reportsZipEnabled }) => {
  const { state } = useFilters()
  if (error)
    return (
      <Fragment>
        <h2 className="text-2xl" aria-live="polite">
          No hay resultados
        </h2>
        <RSS />
        <hr className="mt-4 mb-12 border-t-4 border-gray-900" aria-hidden="true" />
        <ErrorImage className="m-auto block" />
        <h3 className="text-xl text-center md:w-1/2 m-auto">
          Lo sentimos. Hubo un error en nuestro servidor. Por favor, busca nuevamente.
        </h3>
      </Fragment>
    )

  return (
    <div>
      {state.matches('desktop') && (
        <Fragment>
          <div className="flex justify-between items-center">
            <div className="flex items-center relative">
              <h2 className="text-2xl" aria-live="polite">
                {totalCount ? totalCount.toLocaleString('en') : 'No hay'} resultados
              </h2>
              {loading && <Loading className="ml-4 absolute -right-13" />}
            </div>
            <RSS marginClass="ml-auto" />
          </div>
          <hr />
          <div className="flex flex-row-reverse justify-between items-center">
            <SorterField />
            {reportsZipEnabled && <LinkDownloadButton />}
          </div>
        </Fragment>
      )}

      {state.matches('mobile') && (
        <div>
          <RSS />
          <SorterField />
          <div className="flex items-center relative">
            <h2 className="text-2xl" aria-live="polite">
              {totalCount ? totalCount.toLocaleString('en') : 'No hay'} resultados
            </h2>
            {loading && <Loading className="ml-4 absolute left-52" />}
          </div>
        </div>
      )}

      {totalCount === 0 && (
        <Fragment>
          <hr className="mt-4 mb-12 border-t-4 border-gray-900" aria-hidden="true" />
          <section className="mb-12">
            <h3 className="flex items-center mb-8">
              <LightningIcon className="mr-4" />
              Recomendaciones para mejorar tu búsquedas
            </h3>
            <ul>
              <li className="flex items-center mb-4">
                <FullArrowRightIcon className="mr-4" />
                Revisa si hay filtros activados y desactívalos.
              </li>
              <li className="flex items-center mb-4">
                <FullArrowRightIcon className="mr-4" />
                Revisa la redacción de tu búsqueda.
              </li>
              <li className="flex items-center mb-4">
                <FullArrowRightIcon className="mr-4" />
                Prueba usar otros términos más generales.
              </li>
            </ul>
          </section>
          <section className="mb-12">
            <h3 className="flex items-center mb-8">
              <QuestionIcon className="mr-4" />
              ¿No encontraste lo que buscas?
            </h3>
            <ul>
              <li className="mb-4">
                <a className="flex items-center" href="/1021">
                  <FullArrowRightIcon className="mr-4" />
                  Conoce cómo funciona Gob.pe
                </a>
              </li>
              <li className="flex items-center mb-4">
                <FullArrowRightIcon className="mr-4" />
                Avísanos qué información no has encontrado.
              </li>
            </ul>
          </section>
          <SearchFeedback label="Informar falta de contenido" searchId={searchId} widthClass="w-full md:w-1/2" />
        </Fragment>
      )}
      <ul className="results">
        {results.filter(Boolean).map(result => (
          <Result key={result.id} item={result} />
        ))}
      </ul>
    </div>
  )
}

export default Results
