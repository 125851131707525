import React, { Fragment, Suspense, useLayoutEffect } from 'react'
import TypeField from '../search/TypeField'
import Button from '../ui/Button'
import { useFilters } from './FiltersProvider'
import CloseIcon from '../icons/Close'
import { contentHasPublicationDate, contentTypes, formatSelected } from './helpers'
import SubtypeField from './SubtypeField'
import ThemeField from './ThemeField'
import Daterange from '../search/Daterange'
import Section from '../search/Section'
import FiltersTriangle from './FiltersTriangle'
import Loading from '../ui/Loading'
import Modal from '../Modal'
import WithSizes from 'react-sizes'
import ArrowLeft from '../icons/ArrowLeft'
import FilterCount from './FilterCount'

const Filters = ({ availableOptions, isMobile }) => {
  const { state, changeContentType, submit, cancel, changeField, removeAllFilters } = useFilters()
  const isOpen = state.matches('open')
  if (state.matches('closed')) return null

  const handleSubmit = e => {
    e.preventDefault()
    submit()
  }

  const Container = isMobile ? Modal : Fragment
  const modalProps = isMobile ? { show: isOpen, onRequestClose: () => send('CANCEL'), variant: 'institutional' } : {}
  const positionClasses = isMobile ? '' : 'absolute left-0 right-0 '
  const classes = isMobile ? '' : 'z-10 pt-4 pb-6 bg-white shadow-filters'
  return (
    <Container {...modalProps}>
      <div className={positionClasses + ' ' + classes}>
        {!isMobile && <FiltersTriangle />}
        <form className="container w-full max-w-full md:max-w-6xl" onSubmit={handleSubmit}>
          {isMobile ? (
            <div className="flex items-center py-6 gap-4">
              <button aria-label="Cerrar filtros" onClick={() => cancel()}>
                <ArrowLeft className="w-4 h-auto" />
              </button>
              Filtros <FilterCount className="ml-2" />
            </div>
          ) : (
            <div className=" grid grid-cols-5 gap-7">
              <div />
              <div />
              <div />
              <div />
              <button
                type="button"
                onClick={cancel}
                className="flex items-center gap-2 bg-gray-70 ml-auto z-20 text-blue-700 py-1 px-2 rounded-lg font-medium hover:bg-gray-100"
              >
                Cerrar
                <span className="text-blue-700 w-4 h-auto">
                  <CloseIcon />
                </span>
              </button>
            </div>
          )}
          <div className="grid md:grid-cols-institutions-filter gap-7 mb-6 bg-gray-70 md:bg-white -mx-4 md:mx-0 px-4 md:px-0 py-4 md:py-0">
            <TypeField
              isMulti={false}
              label="Tipos de contenido"
              name="contentType"
              placeholder="Todos"
              value={state.context.contentType}
              onChange={changeContentType}
              isClearable={false}
              options={contentTypes}
            />
            <SubtypeField availableOptions={availableOptions} />
            <ThemeField availableOptions={availableOptions} />
            <Suspense fallback={<Loading show />}>
              <Section title="Fecha de publicación">
                <Daterange
                  name="datetype"
                  onChangeStart={e => changeField('desde', formatSelected(e))}
                  onChangeEnd={e => changeField('hasta', formatSelected(e))}
                  valueStart={state.context.desde}
                  valueEnd={state.context.hasta}
                  disabled={!contentHasPublicationDate(state.context.contentType)}
                />
              </Section>
            </Suspense>
          </div>
          <Button variant="primary" type="submit">
            Aplicar
          </Button>
          <Button variant="link" type="button" onClick={removeAllFilters}>
            Limpiar
          </Button>
        </form>
      </div>
    </Container>
  )
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768
})

export default WithSizes(mapSizesToProps)(Filters)
