import React, { Fragment, Suspense } from 'react'
import ArrowRight from '../icons/ArrowRight'
import Button from '../ui/Button'
import Checkbox from '../ui/checkbox'
import Loading from '../ui/Loading'
import Daterange from './Daterange'
import { useFilters } from './FiltersProvider'
import InstitutionField from './InstitutionField'
import Section from './Section'
import TypeField from './TypeField'

function formatRound(count) {
  if (count > 100) {
    const text = count.toString()
    count = parseInt(text.substr(0, text.length - 2) + '00').toLocaleString() + '+'
  }
  return count
}

const formatSelected = selected => {
  if (!selected) return selected
  if (selected.constructor === Array) {
    return selected.map(s => s.value)
  } else {
    return selected.value
  }
}

const Filters = ({ options, counts, topicName, topicId }) => {
  const { state, send, onFocusCalendar } = useFilters()
  const isMobile = state.matches('mobile')
  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        send('SUBMIT')
        send('CLOSE')
      }}
      className="search"
    >
      <h2 id="filtros" className="text-xl mb-4">
        Filtros de búsqueda
      </h2>
      {isMobile && (
        <Fragment>
          <a className="flex items-center font-bold text-lg cursor-pointer mb-4" onClick={() => send('CANCEL')}>
            <ArrowRight className="transform rotate-180 mr-2" /> Regresar
          </a>
        </Fragment>
      )}
      {!isMobile && !topicId && <InstitutionField />}
      <div className={`bg-gray-70 p-4`}>
        <Suspense fallback={<Loading show />}>
          <Section title="Fecha de publicación">
            <Daterange
              name="datetype"
              onFocusCalendar={onFocusCalendar}
              onChangeStart={e =>
                send('CHANGE', {
                  key: 'desde',
                  value: formatSelected(e)
                })
              }
              onChangeEnd={e =>
                send('CHANGE', {
                  key: 'hasta',
                  value: formatSelected(e)
                })
              }
              valueStart={state.context.values.desde}
              valueEnd={state.context.values.hasta}
            />
          </Section>
        </Suspense>
        <hr aria-hidden="true" />
        <h4 className="text-lg mb-4">Tipos de contenido</h4>
        <Checkbox
          label="Todos los contenidos"
          onChange={e => send('CHANGE_ALL_CONTENT', { value: e.target.checked })}
          className="mb-0"
          checked={
            state.context.values.servicios &&
            state.context.values.normas &&
            state.context.values.noticias &&
            state.context.values.campañas &&
            state.context.values.instituciones &&
            state.context.values.servidores &&
            state.context.values.institucional &&
            state.context.values.publicaciones
          }
          count={formatRound(counts.todos)}
        />
        <hr aria-hidden="true" />
        <Checkbox
          label="Orientación, trámites y servicios"
          checked={state.context.values.servicios}
          onChange={e => send('CHANGE', { key: 'servicios', value: e.target.checked })}
          className={options?.segments?.length ? 'mb-4' : ''}
          count={formatRound(counts.servicios)}
        />
        {options?.segments?.length ? (
          <TypeField
            placeholder="Todos los segmentos"
            value={state.context.values.tipo_segmento}
            options={options.segments}
            onChange={e =>
              send('CHANGE', {
                key: 'tipo_segmento',
                value: e
              })
            }
          />
        ) : null}
        <hr aria-hidden="true" />
        <Checkbox
          label="Noticias"
          checked={state.context.values.noticias}
          onChange={e => send('CHANGE', { key: 'noticias', value: e.target.checked })}
          className="mb-4"
          count={formatRound(counts.noticias)}
        />
        <TypeField
          placeholder="Todos los tipos"
          value={state.context.values.tipo_noticia}
          options={options.feed_types}
          className="mb-4"
          onChange={e =>
            send('CHANGE', {
              key: 'tipo_noticia',
              value: e
            })
          }
        />
        {state.context.values?.tipo_noticia?.length > 0 ? (
          <Checkbox
            label="Mostrar compendios"
            checked={state.context.values.add_collections_feeds}
            onChange={e => send('CHANGE', { key: 'add_collections_feeds', value: e.target.checked })}
          />
        ) : (
          <Checkbox
            label="Mostrar solo compendios"
            checked={state.context.values.only_collections_feeds}
            onChange={e => send('CHANGE', { key: 'only_collections_feeds', value: e.target.checked })}
          />
        )}
        <hr aria-hidden="true" />
        <Checkbox
          label="Normas y documentos legales"
          checked={state.context.values.normas}
          onChange={e => send('CHANGE', { key: 'normas', value: e.target.checked })}
          className="mb-4"
          count={formatRound(counts.normas)}
        />
        <TypeField
          placeholder="Todos los tipos"
          options={options.group_rule_types}
          value={state.context.values.tipo_norma}
          className="mb-4"
          onChange={e =>
            send('CHANGE', {
              key: 'tipo_norma',
              value: e
            })
          }
        />
        {state.context.values?.tipo_norma?.length > 0 ? (
          <Checkbox
            label="Mostrar compendios"
            checked={state.context.values.add_collections_rules}
            onChange={e => send('CHANGE', { key: 'add_collections_rules', value: e.target.checked })}
          />
        ) : (
          <Checkbox
            label="Mostrar solo compendios"
            checked={state.context.values.only_collections_rules}
            onChange={e => send('CHANGE', { key: 'only_collections_rules', value: e.target.checked })}
          />
        )}
        <hr aria-hidden="true" />
        <Checkbox
          label="Informes y publicaciones"
          checked={state.context.values.publicaciones}
          onChange={e => send('CHANGE', { key: 'publicaciones', value: e.target.checked })}
          className="mb-4"
          count={formatRound(counts.publicaciones)}
        />
        <TypeField
          placeholder="Todos los tipos"
          options={options.group_report_types}
          value={state.context.values.tipo_publicacion}
          className="mb-4"
          onChange={e =>
            send('CHANGE', {
              key: 'tipo_publicacion',
              value: e
            })
          }
        />
        {state.context.values?.tipo_publicacion?.length > 0 ? (
          <Checkbox
            label="Mostrar compendios"
            checked={state.context.values.add_collections_reports}
            onChange={e => send('CHANGE', { key: 'add_collections_reports', value: e.target.checked })}
          />
        ) : (
          <Checkbox
            label="Mostrar solo compendios"
            checked={state.context.values.only_collections_reports}
            onChange={e => send('CHANGE', { key: 'only_collections_reports', value: e.target.checked })}
          />
        )}
        <hr aria-hidden="true" />
        <Checkbox
          label="Campañas y eventos"
          checked={state.context.values.campañas}
          onChange={e => send('CHANGE', { key: 'campañas', value: e.target.checked })}
          className="mb-4"
          count={formatRound(counts.campañas)}
        />
        <Checkbox
          label="Portales institucionales"
          checked={state.context.values.instituciones}
          onChange={e => send('CHANGE', { key: 'instituciones', value: e.target.checked })}
          className="mb-4"
          count={formatRound(counts.instituciones)}
        />
        <Checkbox
          label="Directorio de funcionarios"
          checked={state.context.values.servidores}
          onChange={e => send('CHANGE', { key: 'servidores', value: e.target.checked })}
          className="mb-4"
          count={formatRound(counts.servidores)}
        />
        <Checkbox
          label="Contenido institucional"
          checked={state.context.values.institucional}
          onChange={e => send('CHANGE', { key: 'institucional', value: e.target.checked })}
          count={formatRound(counts.institucional)}
        />
        <hr aria-hidden="true" />
        <TypeField
          label="Categorías de contenido"
          placeholder="Todas las categorías"
          value={state.context.values.categoria}
          options={options.categories}
          onChange={e => {
            send('CHANGE', {
              key: 'categoria',
              value: e
            })
          }}
        />
      </div>
      {state.matches('mobile') && (
        <div className="fixed bottom-0 left-0 right-0 bg-white p-4">
          <Button type="submit" variant="primary" className="w-full" component="button">
            Mostrar resultados
          </Button>
        </div>
      )}
    </form>
  )
}

export default Filters
