import React from 'react'
import { getFriendlySearchType } from './helpers'

const SearchBreadcrumb = ({ item }) => {
  return (
    <ol className="flex gap-2 font-semibold mb-2">
      {item.parent_type && (
        <li className="flex gap-2">
          <span>{item.parent_type}</span>›
        </li>
      )}
      <li>
        <span>{getFriendlySearchType({ item })}</span>
      </li>
      {item.group_type && (
        <li className="flex gap-2">
          ›<span>{item.group_type}</span>
        </li>
      )}
    </ol>
  )
}

export default SearchBreadcrumb
