import React from 'react'
import { render } from 'react-dom'
import InstitutionSearch from '../components/institution_searcher/InstitutionSearcher'
const registerPage = function () {
  const el = document.querySelector('.js-institution-searcher')
  if (!el) return
  render(
    <InstitutionSearch
      initialData={window?.initialData}
      institutionName={window.institution_name}
      institutionSlug={window.institution_slug}
      institutionReportZipEnabled={window.institution_reports_zip_enabled}
      tabLabelProceduresAndServices={window.tab_label_for_procedures_and_services}
    />,
    el
  )
}

document.addEventListener('turbolinks:load', registerPage)
