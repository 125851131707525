import React from 'react'

const LightningIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" {...props}>
      <path
        d="M11.9995 2.51953C6.48953 2.51953 2.01953 6.98953 2.01953 12.4995C2.01953 18.0095 6.48953 22.4795 11.9995 22.4795C17.5095 22.4795 21.9795 18.0095 21.9795 12.4995C21.9795 6.98953 17.5095 2.51953 11.9995 2.51953ZM11.4795 20.4995V14.2395H7.99953L12.9995 4.49953V10.7595H16.3495L11.4795 20.4995Z"
        fill="black"
      />
    </svg>
  )
}

export default LightningIcon
