import React from 'react'

const Button = ({ variant, className, component: Component = 'button', ...props }) => {
  const classes_map = {
    primary: 'bg-blue-700 text-white px-8 py-2 font-bold text-base rounded',
    secondary: 'bg-transparent border-2 border-blue-700 font-bold py-2.5 px-4 text-blue-700 bg-white rounded',
    tertiary: 'rounded-md py-3 px-3 bg-blue-100 text-blue-375 hover:text-blue-375 font-semibold leading-none',
    link: 'text-blue-700 hover:text-blue-700 px-4 py-2 font-bold underline'
  }

  return <Component className={[classes_map[variant]] + ` ${className}`} {...props} />
}

export default Button
