import React from 'react'
import { createElementFromHTML } from '../helpers'
import Person from '../icons/Person'
import ActionLink from '../search/ActionLink'
import BelongsToCollection from '../search/BelongsToCollection'
import PageChildren from '../search/PageChildren'
import PageDisabled from '../search/PageDisabled'
import DocumentCollectionTag from './DocumentCollectionTag'
import PublicationDate from './PublicationDate'
import RuleSubject from './RuleSubject'
import SearchBreadcrumb from './SearchBreadcrumb'

const ResultItem = ({ item }) => {
  const seeMore = createElementFromHTML(item.url)
  const seeMoreHref = seeMore.getAttribute('href')
  return (
    <li>
      <article className="py-5 md:w-3/4" aria-labelledby={`result-title-${item.id}-label`}>
        <h3
          id={`result-title-${item.id}-label`}
          className="text-xl font-semibold mb-2"
          dangerouslySetInnerHTML={{ __html: item.url }}
          aria-describedby={`result-title-${item.id}-description`}
        />
        <RuleSubject item={item} />
        <div className="flex gap-4 items-baseline">
          <SearchBreadcrumb item={item} />
          <DocumentCollectionTag item={item} />
        </div>
        {item.official ? (
          <div className="flex gap-4 mt-2">
            <a href={seeMoreHref} className="track-ga-click" data-origin={`result-photo-${item.id}`}>
              {item.image_url ? (
                <img src={item.image_url} className="w-18.75 h-auto" alt="" />
              ) : (
                <picture className="h-auto bg-blue-200 flex justify-center p-7">
                  <Person />
                </picture>
              )}
            </a>
            <ul className="list-disc list-inside flex flex-col gap-2">
              {item.official.position && (
                <li className="list-disc">
                  <dt className="mr-2 inline-block font-semibold">Cargo: </dt>
                  <dd className="inline-block mb-0">{item.official.position}</dd>
                </li>
              )}
              {item.official.started_at && (
                <li className="list-disc">
                  <dt className="mr-2 inline-block font-semibold">Inicio de labores: </dt>
                  <dd className="inline-block mb-0">{item.official.started_at}</dd>
                </li>
              )}
              {item.official.email && (
                <li className="list-disc">
                  <dt className="mr-2 inline-block font-semibold">Correo electrónico: </dt>
                  <dd className="inline-block mb-0">
                    <a className="underline font-medium" href={`mailto:${item.official.email}`}>
                      {item.official.email}
                    </a>
                  </dd>
                </li>
              )}
              {item.official.phone && (
                <li className="list-disc">
                  <dt className="mr-2 inline-block font-semibold">Teléfono: </dt>
                  <dd className="inline-block mb-0">
                    <a className="underline font-medium" href={`tel:${item.official.phone.numbers}`}>
                      {item.official.phone.numbers}
                    </a>{' '}
                    {item.official.phone.extension && <span>/ Anexo {item.official.phone.extension}</span>}
                  </dd>
                </li>
              )}
            </ul>
          </div>
        ) : (
          <div>
            <PublicationDate item={item} />
            <p
              id={`result-title-${item.id}-description`}
              className="mb-0 inline"
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          </div>
        )}
        <BelongsToCollection item={item} />
        {item.disabled && <PageDisabled className="mt-4" item={item} />}
        {item.expired_campaign ? (
          <span className="bg-gray-140 py-1 px-2 text-blue-960 mt-4 inline-block uppercase text-xs rounded-lg font-bold">
            Campaña culminada
          </span>
        ) : null}
        <PageChildren item={item} />
        {!item.disabled && <ActionLink className="mt-4" item={item} />}
      </article>
      <hr className="bg-blue-200 my-0" />
    </li>
  )
}

export default ResultItem
