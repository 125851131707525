export const getItem = (values = [], collection = []) => {
  if (!values) return null
  if (values.constructor === Array) {
    return values.map(value => collection.find(c => c.value === value) || '')
  } else {
    return collection.find(c => c.value === values) || ''
  }
}

export const allFields = [
  'servicios',
  'instituciones',
  'institucional',
  'servidores',
  'noticias',
  'campañas',
  'normas',
  'publicaciones'
]

export const areFieldsEmpty = (obj, fields = allFields) => fields.reduce((prev, current) => !obj[current] && prev, true)
export const noOrderFields = ['servicios', 'instituciones', 'institucional', 'servidores']

export const isOrderSafeFromArray = arr => {
  const safeArr = arr.filter(Boolean)
  return safeArr.length !== 0 && noOrderFields.reduce((prev, current) => !safeArr.includes(current) && prev, true)
}

export const isOrderSafe = obj => {
  return !areFieldsEmpty(obj) && noOrderFields.reduce((prev, current) => !obj[current] && prev, true)
}

export const isOnlyFeeds = obj => {
  return (
    areFieldsEmpty(
      obj,
      allFields.filter(f => f !== 'noticias')
    ) && obj.noticias
  )
}
