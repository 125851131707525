import React from 'react'

const QuestionIcon = ({ ...props }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5 0.5H0.5V21.5H21.5V0.5ZM11.0117 18C10.195 18 9.54167 17.3467 9.54167 16.53C9.54167 15.7017 10.195 15.0717 11.0117 15.0717C11.84 15.0717 12.47 15.7017 12.47 16.53C12.4583 17.335 11.84 18 11.0117 18ZM14.5233 9.36667C13.6367 10.6617 12.7967 11.07 12.3417 11.8983C12.155 12.2367 12.085 12.4583 12.085 13.5433H9.96167C9.96167 12.9717 9.86833 12.0383 10.3233 11.2333C10.895 10.2183 11.98 9.61167 12.61 8.71333C13.275 7.76833 12.9017 5.995 11.0117 5.995C9.775 5.995 9.16833 6.92833 8.91167 7.72167L6.98667 6.905C7.51167 5.34167 8.92333 4 10.9883 4C12.715 4 13.8933 4.78167 14.5 5.77333C15.0133 6.61333 15.3167 8.18833 14.5233 9.36667Z"
        fill="#074787"
      />
    </svg>
  )
}

export default QuestionIcon
