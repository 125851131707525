import React from 'react'

const NoResutsTermIcon = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 538 220" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M166.008 177.986L140.415 70.3109L345.822 13.8571L400.279 226.327" fill="#DEE3EA" />
      <rect x="168.723" y="55.5156" width="200.553" height="132.702" fill="white" stroke="#C5CAD1" strokeWidth="3" />
      <rect x="170" y="56" width="198" height="19" fill="#DEE3EA" />
      <rect width="138.821" height="1.55979" transform="matrix(1 0 0 -1 221.814 66.5)" fill="white" />
      <circle cx="180.481" cy="65.7211" r="3.11958" fill="white" />
      <circle cx="193.741" cy="65.7211" r="3.11958" fill="white" />
      <circle cx="206.998" cy="65.7211" r="3.11958" fill="white" />
      <g filter="url(#filter0_d_1757_3750)">
        <path
          d="M494 206.638C494 222.442 393.264 244.315 269 244.315C144.736 244.315 44 222.442 44 206.638C44 190.835 144.736 178.023 269 178.023C393.264 178.023 494 190.835 494 206.638Z"
          fill="white"
        />
      </g>
      <g clipPath="url(#clip0_1757_3750)">
        <path
          d="M245.211 29.0646L243.959 28.5013C244.002 28.0681 244.002 27.6348 243.959 27.2448L245.168 26.6382L244.132 24.0819L242.836 24.4719C242.577 24.1252 242.275 23.822 241.929 23.562L242.491 22.3488L239.813 21.2656L239.208 22.4788C238.777 22.4355 238.345 22.4355 237.956 22.4788L237.351 21.309L237.049 21.4389L234.803 22.3488L235.235 23.6053C234.847 23.8653 234.544 24.1686 234.285 24.5152L233.033 24.0819L231.953 26.6816L233.162 27.2882C233.119 27.7214 233.119 28.1547 233.162 28.5447L231.996 29.1512L233.033 31.6642L234.285 31.231C234.544 31.5776 234.847 31.8809 235.192 32.1408L234.89 33.094C234.847 33.1807 234.803 33.3973 234.803 33.3973L237.351 34.4805L237.956 33.2674C238.388 33.3107 238.82 33.3107 239.208 33.2674L239.813 34.4372L242.361 33.3973L241.929 32.1408C242.275 31.8809 242.577 31.5776 242.836 31.231L244.089 31.7076L245.211 29.0646ZM241.281 29.0213C240.979 29.7578 240.418 30.3211 239.683 30.6244C239.338 30.7544 238.906 30.841 238.561 30.841C238.172 30.841 237.783 30.7544 237.438 30.6244C236.704 30.3211 236.142 29.7578 235.84 29.0213C235.538 28.2847 235.538 27.4615 235.84 26.7249C236.445 25.2084 238.215 24.5152 239.683 25.1218C240.418 25.4251 240.979 25.9883 241.281 26.7249C241.584 27.4615 241.584 28.2847 241.281 29.0213Z"
          fill="#CF000B"
        />
      </g>
      <g clipPath="url(#clip1_1757_3750)">
        <path
          d="M380.916 167.977L380.401 167.745C380.418 167.567 380.418 167.388 380.401 167.228L380.899 166.978L380.472 165.925L379.938 166.086C379.832 165.943 379.707 165.818 379.565 165.711L379.796 165.212L378.693 164.766L378.445 165.265C378.267 165.247 378.089 165.247 377.929 165.265L377.68 164.783L377.555 164.837L376.631 165.212L376.809 165.729C376.648 165.836 376.524 165.961 376.417 166.104L375.902 165.925L375.457 166.996L375.955 167.245C375.937 167.424 375.937 167.602 375.955 167.763L375.475 168.013L375.902 169.047L376.417 168.869C376.524 169.012 376.648 169.137 376.791 169.244L376.666 169.636C376.648 169.672 376.631 169.761 376.631 169.761L377.68 170.207L377.929 169.708C378.107 169.725 378.284 169.725 378.445 169.708L378.693 170.189L379.743 169.761L379.565 169.244C379.707 169.137 379.832 169.012 379.938 168.869L380.454 169.065L380.916 167.977ZM379.298 167.959C379.174 168.262 378.942 168.494 378.64 168.619C378.498 168.673 378.32 168.708 378.178 168.708C378.018 168.708 377.858 168.673 377.715 168.619C377.413 168.494 377.182 168.262 377.057 167.959C376.933 167.656 376.933 167.317 377.057 167.014C377.306 166.389 378.036 166.104 378.64 166.353C378.942 166.478 379.174 166.71 379.298 167.014C379.423 167.317 379.423 167.656 379.298 167.959Z"
          fill="white"
        />
      </g>
      <g clipPath="url(#clip2_1757_3750)">
        <path
          d="M336.461 44.3858L335.503 43.9551C335.536 43.6237 335.536 43.2924 335.503 42.9942L336.428 42.5303L335.635 40.5755L334.645 40.8737C334.446 40.6086 334.215 40.3767 333.951 40.1779L334.38 39.2502L332.333 38.4219L331.87 39.3496C331.54 39.3165 331.21 39.3165 330.913 39.3496L330.45 38.455L330.219 38.5544L328.502 39.2502L328.832 40.211C328.535 40.4098 328.304 40.6418 328.106 40.9068L327.148 40.5755L326.322 42.5635L327.247 43.0273C327.214 43.3587 327.214 43.69 327.247 43.9882L326.355 44.452L327.148 46.3738L328.106 46.0424C328.304 46.3075 328.535 46.5394 328.799 46.7382L328.568 47.4671C328.535 47.5334 328.502 47.6991 328.502 47.6991L330.45 48.5274L330.913 47.5997C331.243 47.6328 331.573 47.6328 331.87 47.5997L332.333 48.4943L334.281 47.6991L333.951 46.7382C334.215 46.5394 334.446 46.3075 334.645 46.0424L335.602 46.4069L336.461 44.3858ZM333.456 44.3527C333.224 44.9159 332.795 45.3466 332.234 45.5786C331.97 45.678 331.639 45.7442 331.375 45.7442C331.078 45.7442 330.781 45.678 330.516 45.5786C329.955 45.3466 329.526 44.9159 329.295 44.3527C329.063 43.7894 329.063 43.1599 329.295 42.5966C329.757 41.437 331.111 40.9068 332.234 41.3707C332.795 41.6026 333.224 42.0334 333.456 42.5966C333.687 43.1599 333.687 43.7894 333.456 44.3527Z"
          fill="white"
        />
      </g>
      <g clipPath="url(#clip3_1757_3750)">
        <path
          d="M199.199 35.2126L198.463 34.8813C198.488 34.6264 198.488 34.3716 198.463 34.1422L199.174 33.7854L198.564 32.2816L197.802 32.511C197.65 32.3071 197.472 32.1287 197.269 31.9758L197.599 31.2622L196.024 30.625L195.668 31.3386C195.414 31.3131 195.16 31.3131 194.932 31.3386L194.576 30.6505L194.398 30.7269L193.077 31.2622L193.331 32.0013C193.102 32.1542 192.925 32.3326 192.772 32.5365L192.035 32.2816L191.4 33.8108L192.112 34.1677C192.086 34.4225 192.086 34.6774 192.112 34.9068L191.426 35.2636L192.035 36.7418L192.772 36.487C192.925 36.6909 193.102 36.8693 193.306 37.0222L193.128 37.5829C193.102 37.6339 193.077 37.7613 193.077 37.7613L194.576 38.3985L194.932 37.6848C195.186 37.7103 195.44 37.7103 195.668 37.6848L196.024 38.373L197.523 37.7613L197.269 37.0222C197.472 36.8693 197.65 36.6909 197.802 36.487L198.539 36.7673L199.199 35.2126ZM196.888 35.1871C196.71 35.6204 196.38 35.9517 195.948 36.1301C195.744 36.2066 195.49 36.2576 195.287 36.2576C195.059 36.2576 194.83 36.2066 194.627 36.1301C194.195 35.9517 193.865 35.6204 193.687 35.1871C193.509 34.7539 193.509 34.2696 193.687 33.8363C194.042 32.9443 195.084 32.5365 195.948 32.8933C196.38 33.0717 196.71 33.4031 196.888 33.8363C197.065 34.2696 197.065 34.7539 196.888 35.1871Z"
          fill="#DEE3EA"
        />
      </g>
      <g clipPath="url(#clip4_1757_3750)">
        <path
          d="M195.3 112.169L194.416 111.772C194.447 111.466 194.447 111.16 194.416 110.885L195.27 110.456L194.538 108.652L193.624 108.927C193.441 108.683 193.227 108.469 192.983 108.285L193.38 107.429L191.49 106.664L191.063 107.52C190.758 107.49 190.453 107.49 190.179 107.52L189.752 106.695L189.539 106.786L187.953 107.429L188.258 108.316C187.984 108.499 187.77 108.713 187.588 108.958L186.704 108.652L185.941 110.487L186.795 110.915C186.764 111.221 186.764 111.527 186.795 111.802L185.972 112.23L186.704 114.004L187.588 113.698C187.77 113.943 187.984 114.157 188.228 114.341L188.014 115.014C187.984 115.075 187.953 115.228 187.953 115.228L189.752 115.992L190.179 115.136C190.484 115.166 190.788 115.166 191.063 115.136L191.49 115.962L193.288 115.228L192.983 114.341C193.227 114.157 193.441 113.943 193.624 113.698L194.508 114.035L195.3 112.169ZM192.526 112.139C192.313 112.659 191.916 113.056 191.398 113.27C191.154 113.362 190.849 113.423 190.606 113.423C190.331 113.423 190.057 113.362 189.813 113.27C189.295 113.056 188.898 112.659 188.685 112.139C188.472 111.619 188.472 111.038 188.685 110.518C189.112 109.447 190.362 108.958 191.398 109.386C191.916 109.6 192.313 109.998 192.526 110.518C192.739 111.038 192.739 111.619 192.526 112.139Z"
          fill="#DEE3EA"
        />
      </g>
      <g clipPath="url(#clip5_1757_3750)">
        <path
          d="M112.631 137.377L111.894 137.045C111.92 136.79 111.92 136.536 111.894 136.306L112.606 135.949L111.996 134.446L111.234 134.675C111.081 134.471 110.904 134.293 110.7 134.14L111.031 133.426L109.456 132.789L109.1 133.503C108.846 133.477 108.592 133.477 108.363 133.503L108.008 132.815L107.83 132.891L106.509 133.426L106.763 134.165C106.534 134.318 106.356 134.497 106.204 134.701L105.467 134.446L104.832 135.975L105.543 136.332C105.518 136.587 105.518 136.841 105.543 137.071L104.857 137.428L105.467 138.906L106.204 138.651C106.356 138.855 106.534 139.033 106.737 139.186L106.559 139.747C106.534 139.798 106.509 139.925 106.509 139.925L108.008 140.563L108.363 139.849C108.617 139.874 108.871 139.874 109.1 139.849L109.456 140.537L110.954 139.925L110.7 139.186C110.904 139.033 111.081 138.855 111.234 138.651L111.97 138.931L112.631 137.377ZM110.319 137.351C110.141 137.784 109.811 138.116 109.379 138.294C109.176 138.371 108.922 138.422 108.719 138.422C108.49 138.422 108.262 138.371 108.058 138.294C107.626 138.116 107.296 137.784 107.118 137.351C106.941 136.918 106.941 136.434 107.118 136C107.474 135.108 108.516 134.701 109.379 135.057C109.811 135.236 110.141 135.567 110.319 136C110.497 136.434 110.497 136.918 110.319 137.351Z"
          fill="#DEE3EA"
        />
      </g>
      <g clipPath="url(#clip6_1757_3750)">
        <path
          d="M150.066 164.718L148.445 163.989C148.501 163.428 148.501 162.867 148.445 162.363L150.01 161.578L148.669 158.27L146.992 158.774C146.657 158.326 146.266 157.933 145.818 157.597L146.545 156.027L143.08 154.625L142.297 156.195C141.739 156.139 141.18 156.139 140.677 156.195L139.894 154.681L139.503 154.849L136.597 156.027L137.156 157.653C136.653 157.989 136.262 158.382 135.926 158.83L134.305 158.27L132.908 161.634L134.473 162.419C134.417 162.98 134.417 163.54 134.473 164.045L132.964 164.83L134.305 168.082L135.926 167.521C136.262 167.97 136.653 168.362 137.1 168.699L136.709 169.932C136.653 170.045 136.597 170.325 136.597 170.325L139.894 171.727L140.677 170.157C141.236 170.213 141.794 170.213 142.297 170.157L143.08 171.671L146.377 170.325L145.818 168.699C146.266 168.362 146.657 167.97 146.992 167.521L148.613 168.138L150.066 164.718ZM144.98 164.662C144.589 165.615 143.862 166.344 142.912 166.736C142.465 166.905 141.906 167.017 141.459 167.017C140.956 167.017 140.453 166.905 140.006 166.736C139.056 166.344 138.329 165.615 137.938 164.662C137.547 163.708 137.547 162.643 137.938 161.69C138.721 159.727 141.012 158.83 142.912 159.615C143.862 160.008 144.589 160.737 144.98 161.69C145.371 162.643 145.371 163.708 144.98 164.662Z"
          fill="#DEE3EA"
        />
      </g>
      <g clipPath="url(#clip7_1757_3750)">
        <path
          d="M280.309 18.447L279.572 18.1157C279.597 17.8608 279.597 17.6059 279.572 17.3766L280.283 17.0197L279.674 15.516L278.912 15.7454C278.759 15.5415 278.581 15.3631 278.378 15.2102L278.708 14.4965L277.133 13.8594L276.778 14.573C276.524 14.5475 276.27 14.5475 276.041 14.573L275.685 13.8849L275.507 13.9613L274.186 14.4965L274.44 15.2357C274.212 15.3886 274.034 15.567 273.882 15.7709L273.145 15.516L272.51 17.0452L273.221 17.402C273.196 17.6569 273.196 17.9118 273.221 18.1412L272.535 18.498L273.145 19.9762L273.882 19.7213C274.034 19.9252 274.212 20.1036 274.415 20.2566L274.237 20.8173C274.212 20.8682 274.186 20.9957 274.186 20.9957L275.685 21.6328L276.041 20.9192C276.295 20.9447 276.549 20.9447 276.778 20.9192L277.133 21.6074L278.632 20.9957L278.378 20.2566C278.581 20.1036 278.759 19.9252 278.912 19.7213L279.648 20.0017L280.309 18.447ZM277.997 18.4215C277.819 18.8548 277.489 19.1861 277.057 19.3645C276.854 19.441 276.6 19.492 276.397 19.492C276.168 19.492 275.939 19.441 275.736 19.3645C275.304 19.1861 274.974 18.8548 274.796 18.4215C274.618 17.9882 274.618 17.504 274.796 17.0707C275.152 16.1787 276.193 15.7709 277.057 16.1277C277.489 16.3061 277.819 16.6374 277.997 17.0707C278.175 17.504 278.175 17.9882 277.997 18.4215Z"
          fill="#DEE3EA"
        />
      </g>
      <path
        d="M136.774 89.7629L135.23 89.0685C135.284 88.5344 135.284 88.0002 135.23 87.5195L136.721 86.7717L135.443 83.6203L133.846 84.101C133.527 83.6737 133.154 83.2998 132.728 82.9794L133.42 81.4838L130.119 80.1484L129.374 81.644C128.842 81.5906 128.309 81.5906 127.83 81.644L127.085 80.2019L126.712 80.3621L123.944 81.4838L124.476 83.0328C123.997 83.3533 123.624 83.7272 123.305 84.1545L121.761 83.6203L120.43 86.8251L121.92 87.5729C121.867 88.1071 121.867 88.6412 121.92 89.1219L120.483 89.8697L121.761 92.9677L123.305 92.4336C123.624 92.8609 123.997 93.2348 124.423 93.5553L124.05 94.7304C123.997 94.8372 123.944 95.1043 123.944 95.1043L127.085 96.4396L127.83 94.944C128.362 94.9974 128.895 94.9974 129.374 94.944L130.119 96.3862L133.26 95.1043L132.728 93.5553C133.154 93.2348 133.527 92.8609 133.846 92.4336L135.39 93.0211L136.774 89.7629ZM131.929 89.7095C131.557 90.6175 130.865 91.3119 129.96 91.6858C129.534 91.846 129.001 91.9529 128.575 91.9529C128.096 91.9529 127.617 91.846 127.191 91.6858C126.286 91.3119 125.594 90.6175 125.221 89.7095C124.849 88.8014 124.849 87.7866 125.221 86.8786C125.967 85.0091 128.149 84.1545 129.96 84.9023C130.865 85.2761 131.557 85.9705 131.929 86.8786C132.302 87.7866 132.302 88.8014 131.929 89.7095Z"
        fill="#CF000B"
      />
      <g clipPath="url(#clip8_1757_3750)">
        <path
          d="M394.953 143.619L394.217 143.288C394.242 143.033 394.242 142.778 394.217 142.548L394.928 142.192L394.318 140.688L393.556 140.917C393.404 140.713 393.226 140.535 393.023 140.382L393.353 139.668L391.778 139.031L391.422 139.745C391.168 139.719 390.914 139.719 390.685 139.745L390.33 139.057L390.152 139.133L388.831 139.668L389.085 140.408C388.856 140.56 388.679 140.739 388.526 140.943L387.789 140.688L387.154 142.217L387.866 142.574C387.84 142.829 387.84 143.084 387.866 143.313L387.18 143.67L387.789 145.148L388.526 144.893C388.679 145.097 388.856 145.276 389.06 145.428L388.882 145.989C388.856 146.04 388.831 146.168 388.831 146.168L390.33 146.805L390.685 146.091C390.939 146.117 391.193 146.117 391.422 146.091L391.778 146.779L393.277 146.168L393.023 145.428C393.226 145.276 393.404 145.097 393.556 144.893L394.293 145.174L394.953 143.619ZM392.642 143.593C392.464 144.027 392.133 144.358 391.702 144.536C391.498 144.613 391.244 144.664 391.041 144.664C390.812 144.664 390.584 144.613 390.381 144.536C389.949 144.358 389.618 144.027 389.441 143.593C389.263 143.16 389.263 142.676 389.441 142.243C389.796 141.351 390.838 140.943 391.702 141.3C392.133 141.478 392.464 141.809 392.642 142.243C392.819 142.676 392.819 143.16 392.642 143.593Z"
          fill="#CF000B"
        />
      </g>
      <path
        d="M404.313 85.8101L401.587 84.5842C401.681 83.6412 401.681 82.6982 401.587 81.8495L404.219 80.5293L401.963 74.9655L399.144 75.8142C398.58 75.0598 397.922 74.3997 397.17 73.8339L398.392 71.1935L392.564 68.8359L391.248 71.4764C390.308 71.3821 389.368 71.3821 388.522 71.4764L387.206 68.9302L386.548 69.2131L381.661 71.1935L382.601 73.9282C381.755 74.494 381.097 75.1541 380.533 75.9085L377.807 74.9655L375.457 80.6236L378.089 81.9438C377.995 82.8868 377.995 83.8298 378.089 84.6785L375.551 85.9987L377.807 91.4682L380.533 90.5252C381.097 91.2796 381.755 91.9397 382.507 92.5055L381.849 94.5802C381.755 94.7688 381.661 95.2403 381.661 95.2403L387.206 97.5978L388.522 94.9574C389.462 95.0517 390.402 95.0517 391.248 94.9574L392.564 97.5035L398.11 95.2403L397.17 92.5055C397.922 91.9397 398.58 91.2796 399.144 90.5252L401.869 91.5625L404.313 85.8101ZM395.76 85.7158C395.102 87.319 393.88 88.5449 392.282 89.205C391.53 89.4879 390.59 89.6765 389.838 89.6765C388.992 89.6765 388.146 89.4879 387.394 89.205C385.796 88.5449 384.574 87.319 383.916 85.7158C383.259 84.1127 383.259 82.321 383.916 80.7179C385.232 77.4173 389.086 75.9085 392.282 77.2287C393.88 77.8888 395.102 79.1148 395.76 80.7179C396.418 82.321 396.418 84.1127 395.76 85.7158Z"
        fill="#DEE3EA"
      />
      <path
        d="M150.846 54.5986C155.915 18.0336 190.23 7.70192 214.407 18.0337"
        stroke="#C0CBDE"
        strokeWidth="2"
        strokeLinecap="square"
        strokeDasharray="1 8"
      />
      <path
        d="M400.336 173.058C397.982 154.18 413.686 144.46 427.099 146.378"
        stroke="#C0CBDE"
        strokeWidth="2"
        strokeLinecap="square"
        strokeDasharray="1 8"
      />
      <path
        d="M181.397 123.244C170.088 148.201 135.383 140.401 135.383 118.174"
        stroke="#C0CBDE"
        strokeWidth="2"
        strokeLinecap="square"
        strokeDasharray="1 8"
      />
      <path
        d="M413.85 80.5321C413.849 117.967 380.704 131.225 355.852 129.962"
        stroke="#C0CBDE"
        strokeWidth="2"
        strokeLinecap="square"
        strokeDasharray="1 8"
      />
      <path
        d="M286 118C287.105 118 288 117.105 288 116C288 114.895 287.105 114 286 114C284.895 114 284 114.895 284 116C284 117.105 284.895 118 286 118Z"
        fill="#3D4053"
      />
      <path
        d="M250 118C251.105 118 252 117.105 252 116C252 114.895 251.105 114 250 114C248.895 114 248 114.895 248 116C248 117.105 248.895 118 250 118Z"
        fill="#3D4053"
      />
      <path
        d="M288 141.012C288 148.751 279.27 139.011 268.5 139.011C257.73 139.011 249 148.751 249 141.012C249 133.274 257.73 127 268.5 127C279.27 127 288 133.274 288 141.012Z"
        fill="#C5CDDA"
      />
      <path
        d="M250 122L250 122C251 123 252 125 251.5 126C250.5 128 248.5 127 248.5 125.5C248.5 123.5 249.5 122.5 250 122Z"
        fill="#DEE3EA"
      />
      <path
        d="M297.148 69.748C296.844 68.684 296.692 67.5187 296.692 66.252C296.692 63.82 297.275 61.8187 298.44 60.248C299.656 58.6773 301.429 56.9547 303.76 55.08C305.939 53.3067 307.56 51.7107 308.624 50.292C309.739 48.8733 310.296 47.1253 310.296 45.048C310.296 42.9707 309.561 41.2733 308.092 39.956C306.673 38.588 304.672 37.904 302.088 37.904C300.517 37.904 299.149 38.2333 297.984 38.892C296.819 39.5 295.881 40.2347 295.172 41.096C294.513 41.9067 294.083 42.6413 293.88 43.3L286.28 39.5C287.091 38.0813 287.927 36.7893 288.788 35.624C289.7 34.4587 291.321 33.268 293.652 32.052C295.983 30.836 298.947 30.228 302.544 30.228C305.787 30.228 308.675 30.8613 311.208 32.128C313.792 33.344 315.793 35.0413 317.212 37.22C318.631 39.348 319.34 41.704 319.34 44.288C319.34 46.6693 318.935 48.7213 318.124 50.444C317.313 52.1667 316.325 53.6107 315.16 54.776C314.045 55.8907 312.576 57.1573 310.752 58.576C308.725 60.1467 307.256 61.464 306.344 62.528C305.432 63.592 304.976 64.9093 304.976 66.48C304.976 67.4933 305.027 68.5827 305.128 69.748H297.148ZM301.86 87.76C300.289 87.76 298.972 87.2533 297.908 86.24C296.895 85.176 296.388 83.884 296.388 82.364C296.388 80.7933 296.895 79.5013 297.908 78.488C298.972 77.424 300.289 76.892 301.86 76.892C303.38 76.892 304.672 77.424 305.736 78.488C306.8 79.5013 307.332 80.7933 307.332 82.364C307.332 83.884 306.8 85.176 305.736 86.24C304.672 87.2533 303.38 87.76 301.86 87.76Z"
        fill="#C5CDDA"
      />
      <defs>
        <filter
          id="filter0_d_1757_3750"
          x="0"
          y="109.023"
          width="538"
          height="154.291"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-25" />
          <feGaussianBlur stdDeviation="22" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.878628 0 0 0 0 0.910103 0 0 0 0 0.954167 0 0 0 0.45 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1757_3750" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1757_3750" result="shape" />
        </filter>
        <clipPath id="clip0_1757_3750">
          <rect width="13.2582" height="13.2582" fill="white" transform="translate(231.953 21.2656)" />
        </clipPath>
        <clipPath id="clip1_1757_3750">
          <rect width="5.45927" height="5.45927" fill="white" transform="translate(375.457 164.766)" />
        </clipPath>
        <clipPath id="clip2_1757_3750">
          <rect width="10.1386" height="10.1386" fill="white" transform="translate(326.322 38.4219)" />
        </clipPath>
        <clipPath id="clip3_1757_3750">
          <rect width="7.79896" height="7.79896" fill="white" transform="translate(191.4 30.625)" />
        </clipPath>
        <clipPath id="clip4_1757_3750">
          <rect width="9.35875" height="9.35875" fill="white" transform="translate(185.941 106.664)" />
        </clipPath>
        <clipPath id="clip5_1757_3750">
          <rect width="7.79896" height="7.79896" fill="white" transform="translate(104.832 132.789)" />
        </clipPath>
        <clipPath id="clip6_1757_3750">
          <rect width="17.1577" height="17.1577" fill="white" transform="translate(132.908 154.625)" />
        </clipPath>
        <clipPath id="clip7_1757_3750">
          <rect width="7.79896" height="7.79896" fill="white" transform="translate(272.51 13.8594)" />
        </clipPath>
        <clipPath id="clip8_1757_3750">
          <rect width="7.79896" height="7.79896" fill="white" transform="translate(387.154 139.031)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NoResutsTermIcon
