import React from 'react'

const TrashIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.66797 1.33327L6.0013 1.99993H2.66797V3.33327H4.66797H11.3346H13.3346V1.99993H10.0013L9.33464 1.33327H6.66797ZM3.33464 4.6666V14.6666H12.668V4.6666H3.33464Z"
        fill="#0056AC"
      />
    </svg>
  )
}

export default TrashIcon
