import React, { useContext } from 'react'
import { FiltersContext } from './FiltersProvider'
import LinkIcon from '../icons/ArrowRight'

const LinkDownloadButton = () => {
  const { state } = useContext(FiltersContext)
  return (
    <a
      className="flex flex-row text-blue-700 underline font-medium whitespace-no-wrap items-center text-sm md:text-base underline font-medium"
      href={`institucion/${state.context.values.institucion[0]}/normas-legales/descarga`}
    >
      Ver documentos por año <LinkIcon className="ml-3" />
    </a>
  )
}

export default LinkDownloadButton
