import React from 'react'

const ArrowDownSelect = () => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.7925 0.874176L6 5.07251L10.2075 0.874176L11.5 2.16668L6 7.66668L0.5 2.16668L1.7925 0.874176Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ArrowDownSelect
