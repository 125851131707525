import uniqBy from 'lodash/uniqBy'
import qs from 'query-string'
import React, { useEffect, useRef, useState } from 'react'
import AsyncSelect from 'react-select/packages/react-select/async'
import { useFilters } from './FiltersProvider'
import { getItem } from './utils'

// const pDebounce = require('p-debounce')
import pDebounce from '../../lib/p-debounce'

let cache = {}

const fetchWithSlug = async term => {
  const query = qs.stringify(
    { term },
    {
      arrayFormat: 'bracket'
    }
  )
  let data = cache[query]
  if (!data) {
    const data = await fetch(`/institutions-by-slug?${query}`)
    const json = await data.json()
    const formattedJson = json.map(d => ({ id: d.id, value: d.slug, label: d.name }))
    cache[query] = formattedJson
    return formattedJson
  }
  return data
}

export const styles = {
  control: (base, state) => ({
    ...base,
    borderRadius: 0,
    border: '2px #26292E solid',
    '&:hover': {
      borderBottom: '2px solid #000'
    }
  }),
  placeholder: (base, state) => ({
    ...base,
    color: '#333',
    fontWeight: '500'
  }),
  indicatorSeparator: (base, state) => ({
    display: 'none'
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: '#333'
  }),
  container: (base, state) => ({
    ...base,
    '&:focus-within': {
      outline: '3px solid #FFD147',
      outlineOffset: 0
    }
  }),
  multiValueLabel: (styles, { data }) => ({
    whiteSpace: 'inherit'
  }),
  option: (provided, state) => ({
    ...provided,
    color: '#333',
    fontSize: '16px',
    background: state.isSelected ? '#e5e5e5' : state.isFocused ? '#f2f2f2' : '#fff',
    '&:active': {
      background: '#f2f2f2'
    }
  })
}

const formatOptionLabel = ({ label }, { inputValue }) => {
  const startIndex = label.toLowerCase().indexOf(inputValue.toLowerCase())
  if (startIndex === -1) return label

  const endIndex = startIndex + inputValue.length
  return (
    <span>
      {label.substring(0, startIndex)}
      <strong>{label.substring(startIndex, endIndex)}</strong>
      {label.substring(endIndex)}
    </span>
  )
}

const InstitutionField = () => {
  const { state, send } = useFilters()
  const value = state.context.values.institucion
  const [newOptions, setNewOptions] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([])

  const onChange = values => {
    values = values || []
    send('CHANGE_SUBMIT', {
      key: 'institucion',
      value: values?.map(v => v.value) || []
    })
    setSelectedOptions([...values])
  }

  const loadOptions = useRef(
    pDebounce(async (searchTerm, callback) => {
      const json = await fetchWithSlug([searchTerm])
      //TODO: necesario agrupar?
      setNewOptions([...newOptions, ...json])
      callback([...newOptions, ...json])
      return json
    }, 500)
  )
  const [defaultOptions, setDefaultOptions] = useState([])

  async function handleDefaultOptions() {
    const data = await fetchWithSlug(value)
    const defaultData = await fetchWithSlug([])
    const newDefaultOptions = uniqBy([...data, ...defaultData], 'id')
    setDefaultOptions(newDefaultOptions)
  }

  useEffect(() => {
    handleDefaultOptions()
  }, [])

  return (
    <div className="mb-4">
      <AsyncSelect
        name="entidades"
        placeholder="Buscar en todas las instituciones"
        onChange={onChange}
        loadOptions={loadOptions.current}
        loadingMessage={() => 'Cargando...'}
        defaultOptions={defaultOptions}
        value={getItem(value, [...newOptions, ...defaultOptions, ...selectedOptions])}
        isMulti
        isClearable={(value || []).length > 1}
        styles={styles}
        cacheOptions={false}
        formatOptionLabel={formatOptionLabel}
      />
    </div>
  )
}

export default React.memo(InstitutionField)
