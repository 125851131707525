import React from 'react'
import SearchIcon from '../icons/SearchIcon'
import { useFilters } from './FiltersProvider'

const TermField = ({ topicId, topicName }) => {
  const { state, send } = useFilters()
  return (
    <div className="relative">
      <div className="absolute left-4 py-1 top-3 pr-4 border-r border-black">
        <SearchIcon width="24px" height="24px" />
      </div>
      <input
        className="w-full bg-white border-0 border-b-4 border-gray-900 text-xl py-3 pl-18"
        placeholder={topicId ? `Buscar en ${topicName}` : 'Buscar en Gob.pe'}
        autoFocus
        value={state.context.values.term || ''}
        onChange={e =>
          send('CHANGE_SUBMIT', {
            key: 'term',
            value: e.target.value
          })
        }
      />
    </div>
  )
}

export default TermField
