import { useMachine } from '@xstate/react'
import qs from 'query-string'
import React, { useContext, useEffect, useMemo } from 'react'
import withSizes from 'react-sizes'
import { assign, Machine } from 'xstate'
import { isOnlyFeeds, isOrderSafe, isOrderSafeFromArray } from './utils'

const filtersMachine = Machine({
  id: 'filters',
  context: {
    values: {},
    oldValues: {
      categorias: [],
      normas: false,
      noticias: false,
      servicios: false,
      publicaciones: false,
      campañas: false,
      institucional: false,
      servidores: false,
      instituciones: false,
      only_collections_reports: false,
      only_collections_rules: false,
      only_collections_feeds: false,
      add_collections_reports: false,
      add_collections_rules: false,
      add_collections_feeds: false,
      term: ''
    }
  },
  initial: 'desktop',
  states: {
    mobile: {
      initial: 'closed',
      states: {
        opened: {
          entry: ['savePrev', 'hideYieldFooter'],
          exit: ['showYieldFooter'],
          on: {
            CHANGE_ALL_CONTENT: {
              actions: ['onChangeAllContent']
            },
            CHANGE: {
              actions: ['onChange']
            },

            SUBMIT: {
              actions: ['submit']
            },
            CANCEL: {
              target: 'closed',
              actions: 'restorePrev'
            },
            CLOSE: {
              target: 'closed'
            }
          }
        },
        closed: {
          on: {
            OPEN: 'opened'
          }
        }
      },
      on: {
        IS_DESKTOP: 'desktop',
        CHANGE_SUBMIT: {
          actions: ['onChange', 'submit']
        },
        CHANGE_INSTA_SUBMIT: {
          actions: ['onChange', 'instaSubmit']
        }
      }
    },
    desktop: {
      on: {
        IS_MOBILE: 'mobile',
        CHANGE_ALL_CONTENT: {
          actions: ['onChangeAllContent', 'submit']
        },
        CHANGE: {
          actions: ['onChange', 'submit']
        },
        CHANGE_SUBMIT: {
          actions: ['onChange', 'submit']
        },
        CHANGE_INSTA_SUBMIT: {
          actions: ['onChange', 'instaSubmit']
        },
        // agregar insta change
        SUBMIT: {
          actions: ['submit']
        }
      }
    }
  }
})

export const FiltersContext = React.createContext(null)

const FiltersProvider = ({ children, isMobile, onSubmit, onInstaSubmit, onFocusCalendar }) => {
  const initialQueryParsed = useMemo(
    () =>
      qs.parse(window.location.search || '', {
        arrayFormat: 'bracket'
      }),
    []
  )

  const [state, send] = useMachine(
    filtersMachine
      .withContext({
        values: {
          desde: initialQueryParsed.desde,
          hasta: initialQueryParsed.hasta,
          categoria: initialQueryParsed.categoria || [],
          tipo_noticia: initialQueryParsed.tipo_noticia || [],
          tipo_norma: initialQueryParsed.tipo_norma || [],
          tipo_publicacion: initialQueryParsed.tipo_publicacion || [],
          tipo_segmento: initialQueryParsed.tipo_segmento || [],
          normas: initialQueryParsed.contenido?.includes('normas') || false,
          noticias: initialQueryParsed.contenido?.includes('noticias') || false,
          servicios: initialQueryParsed.contenido?.includes('servicios') || false,
          publicaciones: initialQueryParsed.contenido?.includes('publicaciones') || false,
          campañas: initialQueryParsed.contenido?.includes('campañas') || false,
          institucional: initialQueryParsed.contenido?.includes('institucional') || false,
          servidores: initialQueryParsed.contenido?.includes('servidores') || false,
          instituciones: initialQueryParsed.contenido?.includes('instituciones') || false,
          institucion: initialQueryParsed.institucion || [],
          only_collections_reports: initialQueryParsed.only_collections_reports === 'true',
          only_collections_rules: initialQueryParsed.only_collections_rules === 'true',
          only_collections_feeds: initialQueryParsed.only_collections_feeds === 'true',
          add_collections_reports: initialQueryParsed.add_collections_reports === 'true',
          add_collections_rules: initialQueryParsed.add_collections_rules === 'true',
          add_collections_feeds: initialQueryParsed.add_collections_feeds === 'true',
          topic_id: initialQueryParsed.topic_id,
          sheet: initialQueryParsed.sheet || 1,
          sort_by:
            initialQueryParsed.sort_by ||
            (isOrderSafeFromArray(initialQueryParsed.contenido || []) && !initialQueryParsed.term ? 'recent' : 'none'),
          term: initialQueryParsed.term || ''
        }
      })
      .withConfig({
        actions: {
          onChange: assign((ctx, evt) => {
            let additionalChanges = {}

            if (evt.key === 'only_collections_reports' && evt.value)
              additionalChanges = {
                publicaciones: true
              }

            if (evt.key === 'only_collections_rules' && evt.value)
              additionalChanges = {
                normas: true
              }

            if (evt.key === 'only_collections_feeds' && evt.value)
              additionalChanges = {
                noticias: true
              }

            if (evt.key === 'normas' && !evt.value)
              additionalChanges = {
                only_collections_rules: false,
                add_collections_rules: false,
                tipo_norma: []
              }

            if (evt.key === 'publicaciones' && !evt.value)
              additionalChanges = {
                only_collections_reports: false,
                add_collections_reports: false,
                tipo_publicacion: []
              }

            if (evt.key === 'noticias' && !evt.value)
              additionalChanges = {
                tipo_noticia: [],
                only_collections_feeds: false,
                add_collections_feeds: false
              }

            if (evt.key === 'tipo_norma') {
              const commonChanges = { normas: true }

              if (evt.value?.length > 0) {
                additionalChanges = {
                  ...commonChanges,
                  only_collections_rules: false
                }
              } else {
                additionalChanges = {
                  ...commonChanges,
                  add_collections_rules: false
                }
              }
            }

            if (evt.key === 'tipo_publicacion') {
              const commonChanges = { publicaciones: true }

              if (evt.value?.length > 0) {
                additionalChanges = {
                  ...commonChanges,
                  only_collections_reports: false
                }
              } else {
                additionalChanges = {
                  ...commonChanges,
                  add_collections_reports: false
                }
              }
            }

            if (evt.key === 'tipo_noticia') {
              const commonChanges = { noticias: true }

              if (evt.value?.length > 0) {
                additionalChanges = {
                  ...commonChanges,
                  only_collections_feeds: false
                }
              } else {
                additionalChanges = {
                  ...commonChanges,
                  add_collections_feeds: false
                }
              }
            }

            if (evt.key !== 'sheet') additionalChanges.sheet = 1 // Si algun cambio de filtros se regresa a página 1

            const newValues = {
              ...ctx.values,
              ...additionalChanges,
              [evt.key]: evt.value
            }

            if (evt.key === 'sort_by') return { values: newValues } // El usuario siempre puede cambiar el orden

            const safeOrder = isOrderSafe(newValues)
            const prevSafeOrder = isOrderSafe(ctx.values)

            if (safeOrder && ctx.values.term && !newValues.term) newValues.sort_by = 'recent' // Si se elimina termino de busqueda y es seguro ordenar, se pasa a 'Más reciente'
            if (!prevSafeOrder && safeOrder && (!newValues.term || isOnlyFeeds(newValues))) newValues.sort_by = 'recent' // Si antes ordenar no era seguro y ahora si lo es y no hay nada escrito o es solo noticias se pasa a 'Más reciente'
            if (evt.key === 'term' && evt.value && !isOnlyFeeds(newValues)) newValues.sort_by = 'none' // Si se modifica el campo de texto y no es solo noticias se pasa a 'Mas relevante'
            if (!safeOrder) newValues.sort_by = 'none' // Si hay algun campo no seguro de ordenar se pasa a 'Más relevante'

            return { values: newValues }
          }),
          onChangeAllContent: assign((ctx, evt) => ({
            values: {
              ...ctx.values,
              sort_by: 'none',
              normas: evt.value,
              noticias: evt.value,
              servicios: evt.value,
              publicaciones: evt.value,
              campañas: evt.value,
              instituciones: evt.value,
              servidores: evt.value,
              institucional: evt.value
            }
          })),
          submit: ctx => onSubmit(ctx.values),
          instaSubmit: ctx => onInstaSubmit(ctx.values),
          savePrev: assign((ctx, evt) => ({
            oldValues: ctx.values
          })),
          restorePrev: assign((ctx, evt) => ({
            values: ctx.oldValues
          })),
          hideYieldFooter: () => {
            document.querySelector('.yield').style.display = 'none'
            document.querySelector('footer').style.display = 'none'
          },
          showYieldFooter: () => {
            document.querySelector('.yield').style.display = 'block'
            document.querySelector('footer').style.display = 'block'
          }
        }
      })
  )

  useEffect(() => {
    if (isMobile) send('IS_MOBILE')
    else send('IS_DESKTOP')
  }, [isMobile])

  return <FiltersContext.Provider value={{ send, state, onFocusCalendar }}>{children}</FiltersContext.Provider>
}

export const useFilters = () => {
  return useContext(FiltersContext)
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768
})

export default withSizes(mapSizesToProps)(FiltersProvider)
