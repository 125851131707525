import React from 'react'

const DownloadIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" {...props}>
      <g id="Grupo_3453" data-name="Grupo 3453" transform="translate(-246 -5274)">
        <g
          id="Rectángulo_3888"
          data-name="Rectángulo 3888"
          transform="translate(246 5274)"
          fill="none"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="40" height="40" stroke="none" />
          <rect x="0.5" y="0.5" width="39" height="39" fill="none" />
        </g>
        <path
          id="Trazado_2727"
          data-name="Trazado 2727"
          d="M23.941,11.118H18.529V3H10.412v8.118H5l9.471,9.471ZM5,23.294V26H23.941V23.294Z"
          transform="translate(252 5280)"
          fill="#0056ac"
        />
      </g>
    </svg>
  )
}

export default DownloadIcon
