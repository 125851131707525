import React from 'react'

const HelpIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3359 30.0026H21.6693V26.6693H18.3359V30.0026ZM20.0026 3.33594C10.8026 3.33594 3.33594 10.8026 3.33594 20.0026C3.33594 29.2026 10.8026 36.6693 20.0026 36.6693C29.2026 36.6693 36.6693 29.2026 36.6693 20.0026C36.6693 10.8026 29.2026 3.33594 20.0026 3.33594ZM20.0026 33.3359C12.6526 33.3359 6.66927 27.3526 6.66927 20.0026C6.66927 12.6526 12.6526 6.66927 20.0026 6.66927C27.3526 6.66927 33.3359 12.6526 33.3359 20.0026C33.3359 27.3526 27.3526 33.3359 20.0026 33.3359ZM20.0026 10.0026C16.3193 10.0026 13.3359 12.9859 13.3359 16.6693H16.6693C16.6693 14.8359 18.1693 13.3359 20.0026 13.3359C21.8359 13.3359 23.3359 14.8359 23.3359 16.6693C23.3359 20.0026 18.3359 19.5859 18.3359 25.0026H21.6693C21.6693 21.2526 26.6693 20.8359 26.6693 16.6693C26.6693 12.9859 23.6859 10.0026 20.0026 10.0026Z"
        fill="#26292E"
      />
    </svg>
  )
}

export default HelpIcon
