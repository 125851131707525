import React from 'react'

const Close = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 14 13">
      <polygon
        style={{ fill: 'currentColor' }}
        points="13.4,1.7 11.8,0.1 7,4.9 2.2,0.1 0.6,1.7 5.4,6.5 0.6,11.3 2.2,12.9 7,8.1 11.8,12.9 13.4,11.3 
	8.6,6.5 "
      />
    </svg>
  )
}

export default Close
