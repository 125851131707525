import { useMachine } from '@xstate/react'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import qs from 'query-string'
import React from 'react'
import { actions, assign, Machine, send } from 'xstate'
import Filters from './Filters'
import FiltersProvider from './FiltersProvider'
import InstitutionField from './InstitutionField'
import ModalFilters from './ModalFilters'
import OnlyMobile from './OnlyMobile'
import Pagination from './Pagination'
import Results from './Results'
import SearchFeedback from './SearchFeedback'
import Tags from './Tags'
import TermField from './TermField'

const searchMachine = Machine({
  id: 'search',
  initial: 'idle',
  context: {
    searchId: window.initialData?.data?.id,
    results: window.initialData?.data?.attributes?.results,
    filterOptions: window.initialData?.data?.attributes?.filters,
    total_count: window.initialData?.data?.attributes?.total_count,
    counts: window.initialData?.data?.attributes?.filters?.content_types,
    suggestions: window.initialData?.data?.attributes?.suggestions?.[1],
    suggestions_text: window.initialData?.data?.attributes?.suggestions_text?.[0],
    reports_zip_enabled: window.initialData?.data?.attributes?.reports_zip_enabled,
    queryString: '',
    loading: false,
    calendarFocused: false
  },
  states: {
    idle: {},
    searching: {
      after: {
        1000: {
          actions: 'showLoading'
        }
      },
      invoke: {
        src: ctx =>
          fetch(`/busquedas.json?${ctx.queryString}`)
            .then(d => d.json())
            .then(d => d.data),
        onDone: {
          target: 'idle',
          actions: 'saveFetch'
        },
        onError: 'error'
      },
      exit: 'hideLoading'
    },
    error: {}
  },
  on: {
    ACTUAL_SEARCH: {
      target: '.searching',
      internal: false,
      actions: ['queryfy', 'changeQueryPath']
    },
    SEARCH: {
      actions: [
        actions.cancel('search-debounce'),
        send(
          (ctx, evt) => {
            return { type: 'ACTUAL_SEARCH', values: evt.values }
          },
          { delay: 1000, id: 'search-debounce' }
        )
      ]
    },
    FOCUS_CALENDAR: {
      actions: ['focusCalendar']
    }
  }
})

const Search = ({ topicId, topicName }) => {
  const [state, send] = useMachine(
    searchMachine.withConfig({
      actions: {
        changeQueryPath: ctx => {
          const pathname = window.location.pathname
          window.history.pushState({ turbolinks: {} }, '', `${pathname}?${ctx.queryString}`)
        },
        saveFetch: assign((ctx, evt) => ({
          searchId: evt,
          results: evt.data.attributes.results,
          filterOptions: evt.data.attributes.filters,
          total_count: evt.data.attributes.total_count,
          suggestions: evt.data.attributes.suggestions?.[1],
          counts: evt.data?.attributes?.filters?.content_types,
          suggestions_text: evt.data.attributes.suggestions_text?.[0],
          reports_zip_enabled: evt.data?.attributes?.reports_zip_enabled
        })),
        queryfy: assign((ctx, evt) => {
          const { values } = evt
          const contenido = Object.keys(
            pickBy(
              pick(values, [
                'servicios',
                'normas',
                'noticias',
                'campañas',
                'instituciones',
                'servidores',
                'institucional',
                'publicaciones'
              ]),
              Boolean
            )
          )
          const queryString = qs.stringify(
            pickBy(
              {
                contenido,
                only_collections_reports: values.only_collections_reports,
                only_collections_rules: values.only_collections_rules,
                only_collections_feeds: values.only_collections_feeds,
                add_collections_reports: values.add_collections_reports,
                add_collections_rules: values.add_collections_rules,
                add_collections_feeds: values.add_collections_feeds,
                desde: values.desde,
                hasta: values.hasta,
                institucion: values.institucion,
                term: values.term,
                categoria: values.categoria,
                tipo_noticia: values.tipo_noticia,
                tipo_norma: values.tipo_norma,
                tipo_publicacion: values.tipo_publicacion,
                tipo_segmento: values.tipo_segmento,
                sort_by: values.sort_by,
                topic_id: values.topic_id,
                sheet: values.sheet
              },
              Boolean
            ),
            {
              arrayFormat: 'bracket'
            }
          )
          return {
            queryString
          }
        }),
        showLoading: assign((ctx, evt) => ({
          loading: true
        })),
        hideLoading: assign((ctx, evt) => ({
          loading: false
        })),
        focusCalendar: assign((ctx, evt) => ({
          calendarFocused: evt.value
        }))
      }
    })
  )

  return (
    <FiltersProvider
      onSubmit={values => send('SEARCH', { values })}
      onInstaSubmit={values => {
        send('ACTUAL_SEARCH', { values })
        window.scrollTo(0, 0)
      }}
      onFocusCalendar={({ value }) => {
        send('FOCUS_CALENDAR', { value })
      }}
    >
      <div className="bg-gray-100">
        <div className="container ">
          <div className="row py-4">
            <div className="col-md-4">
              <h1 className="leading-tight">{topicId ? `Resultados de búsqueda de ${topicName}` : 'Buscador'}</h1>
            </div>
            <div className="col-md-8">
              <TermField topicId={topicId} topicName={topicName} />
              <Tags filterOptions={state.context.filterOptions} />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-4 mb-12">
        <div className="row">
          <aside
            className={`col-md-4 md:mt-12 search-aside ${state.context.calendarFocused ? '' : 'overflow-y-auto'}`}
            aria-labelledby="filtros"
          >
            {!topicId && (
              <OnlyMobile>
                <InstitutionField />
              </OnlyMobile>
            )}
            <ModalFilters>
              <Filters
                counts={state.context.counts}
                options={state.context.filterOptions}
                topicId={topicId}
                topicName={topicName}
              />
            </ModalFilters>
          </aside>
          <main className="col-md-8" aria-label="Contenido principal" id="main">
            <Results
              error={state.matches('error')}
              totalCount={state.context.total_count}
              results={state.context.results}
              searchId={state.context.searchId}
              loading={state.context.loading}
              reportsZipEnabled={state.context.reports_zip_enabled}
            />
            <nav aria-label="Páginación">
              {!state.matches('error') && <Pagination totalCount={state.context.total_count} />}
            </nav>
          </main>
        </div>
      </div>
      {state.context.total_count !== 0 && <SearchFeedback searchId={state.context.searchId} />}
    </FiltersProvider>
  )
}

export default Search
