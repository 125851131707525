import React from 'react'
import { useFilters } from './FiltersProvider'

const FilterCount = ({ className }) => {
  const { state } = useFilters()
  let count = 0
  Object.keys(state.context.oldValues).forEach(key => {
    if (
      key !== 'contentType' &&
      key !== 'term' &&
      key !== 'sort_by' &&
      key !== 'sheet' &&
      state.context.oldValues[key]
    ) {
      count++
    }
  })
  return <span className={className + ' circle-number' + (count > 0 ? ' text-white bg-green-430' : '')}>{count}</span>
}

export default FilterCount
