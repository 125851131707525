import React from 'react'
import TypeField from '../search/TypeField'
import { useFilters } from './FiltersProvider'
import { apiToKeysMap, subtypesMap } from './helpers'

const SubtypeField = ({ availableOptions }) => {
  const { state, changeField } = useFilters()
  const key = subtypesMap[state.context.contentType]

  return (
    <TypeField
      isMulti={false}
      label="Subtipo"
      name={key}
      placeholder="Todos"
      value={state.context[key]}
      onChange={e => changeField(key, e)}
      options={availableOptions[apiToKeysMap[key]] || []}
      disabled={!key}
      isClearable={false}
      withAllOption
    />
  )
}

export default SubtypeField
