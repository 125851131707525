import React, { useContext, useMemo } from 'react'
import { useMachine } from '@xstate/react'
import qs from 'query-string'
import { assign, Machine } from 'xstate'
import omit from 'lodash/omit'
import { getDefaultsFromQuery, sortableContentTypes } from './helpers'

const filtersMachine = Machine({
  id: 'filters',
  context: { oldValues: {} },
  initial: 'closed',
  states: {
    closed: {
      on: {
        TOGGLE: 'open'
      }
    },
    open: {
      entry: ['savePrev'],
      on: {
        TOGGLE: { target: 'closed', actions: 'restorePrev' },
        CHANGE_CONTENT_TYPE: {
          actions: ['changeContentType', 'cleanFilters']
        },
        CHANGE_FIELD: {
          actions: ['changeField']
        },
        SUBMIT: {
          target: 'closed',
          actions: ['savePrev', 'instaSubmit']
        },
        CANCEL: {
          target: 'closed',
          actions: 'restorePrev'
        },
        REMOVE_ALL_FILTERS: {
          actions: ['removeContentTypeField', 'removeFilters']
        }
      }
    }
  },
  on: {
    REMOVE_FILTER: {
      actions: ['removeFilter', 'cleanFilters', 'savePrev', 'instaSubmit']
    },
    CHANGE_CONTENT_TYPE_FROM_TABS: { actions: ['changeContentType', 'cleanFilters', 'savePrev', 'instaSubmit'] },
    CHANGE_TERM: {
      actions: ['changeField', 'submit']
    },
    CHANGE_SORT_BY: {
      actions: ['changeField', 'savePrev', 'instaSubmit']
    },
    CHANGE_PAGINATION: {
      actions: ['changeField', 'savePrev', 'instaSubmit']
    },
    REMOVE_ALL_FILTERS_AND_APPLY: {
      target: 'closed',
      actions: ['removeFilters', 'cleanFilters', 'savePrev', 'instaSubmit']
    },
    INSTA_SUBMIT: {
      actions: ['instaSubmit']
    }
  }
})

export const FiltersContext = React.createContext(null)

export const useFilters = () => useContext(FiltersContext)

const FiltersProvider = ({ children, onSubmit, onInstaSubmit }) => {
  const initialQueryParsed = useMemo(
    () =>
      qs.parse(window.location.search || '', {
        arrayFormat: 'bracket'
      }),
    []
  )

  const [state, send] = useMachine(
    filtersMachine.withContext(getDefaultsFromQuery(initialQueryParsed)).withConfig({
      actions: {
        changeContentType: assign((_, event) => ({
          contentType: event.contentType,
          sheet: 1
        })),
        submit: ctx =>
          onSubmit({
            contenido: ctx.contentType,
            tipo_noticia: ctx.tipo_noticia,
            tipo_norma: ctx.tipo_norma,
            tipo_publicacion: ctx.tipo_publicacion,
            tipo_institucional: ctx.tipo_institucional,
            term: ctx.term,
            desde: ctx.desde,
            hasta: ctx.hasta,
            sheet: ctx.sheet,
            tema: ctx.tema,
            sort_by: ctx.sort_by
          }),
        instaSubmit: ctx =>
          onInstaSubmit({
            contenido: ctx.contentType,
            tipo_noticia: ctx.tipo_noticia,
            tipo_norma: ctx.tipo_norma,
            tipo_publicacion: ctx.tipo_publicacion,
            tipo_institucional: ctx.tipo_institucional,
            term: ctx.term,
            desde: ctx.desde,
            hasta: ctx.hasta,
            sheet: ctx.sheet,
            tema: ctx.tema,
            sort_by: ctx.sort_by
          }),
        changeField: assign((_, event) => {
          if (event.name === 'sheet') return { sheet: event.value }
          return { [event.name]: event.value, sheet: 1 }
        }),
        savePrev: assign(ctx => ({ oldValues: omit(ctx, ['oldValues']) })),
        restorePrev: assign(ctx => ctx.oldValues),
        removeFilters: assign(ctx => ({
          tipo_noticia: '',
          tipo_norma: '',
          tipo_publicacion: '',
          tipo_institucional: '',
          desde: '',
          hasta: '',
          sheet: 1,
          tema: '',
          sort_by: 'none'
        })),
        cleanFilters: assign((ctx, event) => {
          let changes = {}

          changes.tema = ''

          if (event.contentType || event.name === 'contentType') {
            changes.desde = ''
            changes.hasta = ''
          }
          if (ctx.contentType !== 'noticias') {
            changes.tipo_noticia = ''
          }
          if (ctx.contentType !== 'normas') {
            changes.tipo_norma = ''
          }
          if (ctx.contentType !== 'publicaciones') {
            changes.tipo_publicacion = ''
          }
          if (ctx.contentType === 'todos') {
            changes.sort_by = 'none'
          }
          if (ctx.contentType === 'servicios') {
            changes.sort_by = 'none'
          }
          if (sortableContentTypes.includes(ctx.contentType) && !ctx.term) {
            changes.sort_by = 'recent'
          }
          if (ctx.contentType === 'institucional_completo') {
            changes.tipo_institucional = ''
          }

          return changes
        }),
        removeFilter: assign((_, event) => {
          if (event.name === 'contentType') return { contentType: 'todos' }
          return {
            [event.name]: '',
            sheet: 1
          }
        }),
        removeContentTypeField: assign(() => {
          return {
            contentType: 'todos'
          }
        })
      }
    })
  )

  const value = {
    changeContentType: contentType => send('CHANGE_CONTENT_TYPE', { contentType }),
    changeContentTypeFromTabs: contentType => send('CHANGE_CONTENT_TYPE_FROM_TABS', { contentType }),
    submit: () => send('SUBMIT'),
    instaSubmit: () => send('INSTA_SUBMIT'),
    state,
    initialQueryParsed,
    sheet: state.context.sheet,
    toggleFilters: () => send('TOGGLE'),
    changeField: (name, value) => send('CHANGE_FIELD', { name, value }),
    cancel: () => send('CANCEL'),
    changeTerm: term => send('CHANGE_TERM', { name: 'term', value: term }),
    removeFilter: name => send('REMOVE_FILTER', { name }),
    removeAllFilters: () => send('REMOVE_ALL_FILTERS'),
    removeAllFiltersAndApply: () => send('REMOVE_ALL_FILTERS_AND_APPLY'),
    changeSortBy: sortBy => send('CHANGE_SORT_BY', { name: 'sort_by', value: sortBy }),
    changePage: page => send('CHANGE_PAGINATION', { name: 'sheet', value: page }),
    term: state.context.term
  }

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
}

export default FiltersProvider
