import React, { Fragment } from 'react'

const BelongsToCollection = ({ item }) => {
  return item.collections.length ? (
    <p className="bg-white inline-block text-gray-500 mt-4 mb-0 font-medium">
      Pertenece{' '}
      {item.collections.length === 1 ? (
        <Fragment>
          al compendio:{' '}
          <a className="underline" href={item.collections[0].url}>
            {item.collections[0].title}
          </a>
        </Fragment>
      ) : (
        <Fragment>
          a los compendios:{' '}
          {item.collections.map((c, index) => (
            <Fragment key={c.id}>
              <a className="underline" href={c.url}>
                {c.title}
              </a>
              <span className="text-blue-700 font-medium">
                {index === item.collections.length - 1 ? null : '  •  '}
              </span>
            </Fragment>
          ))}
        </Fragment>
      )}
    </p>
  ) : null
}

export default BelongsToCollection
