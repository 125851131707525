import React from 'react'

const XIcon = ({ ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4.57143 3.42857L8 6.85714L11.4286 3.42857L12.5714 4.57143L9.14286 8L12.5714 11.4286L11.4286 12.5714L8 9.14286L4.57143 12.5714L3.42857 11.4286L6.85714 8L3.42857 4.57143L4.57143 3.42857Z" />
    </svg>
  )
}

export default XIcon
