import React from 'react'
import { render } from 'react-dom'
import Search from '../components/search/Search'

const registerPage = function () {
  const el = document.querySelector('.js-search')
  if (!el) return
  const topicId = el.dataset.topic_id
  const topicName = el.dataset.topic_name
  render(<Search topicId={topicId} topicName={topicName} />, el)
}

document.addEventListener('turbolinks:load', registerPage)
