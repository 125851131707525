import React from 'react'

const page_kind_map = {
  procedure: 'Trámite',
  service: 'Servicio',
  institutional: 'Contenido institucional'
}

const PageDisabled = ({ item, className }) => {
  return (
    <span
      className={
        'bg-neutral-400 leading-none py-1 px-2 text-neutral-700 text-xs font-medium flex item-center w-fit rounded-md uppercase ' +
        className
      }
    >
      {page_kind_map[item.page_kind]} no disponible
    </span>
  )
}

export default PageDisabled
