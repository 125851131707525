import React from 'react'

const Section = ({ title, variant = 'vertical', children, className }) => {
  const classNames = {
    vertical: 'block',
    horizontal: 'flex items-baseline gap-6'
  }
  return (
    <label className={classNames[variant] + ' ' + className}>
      {title && <h4 className="font-bold mb-2 leading-none">{title}</h4>}
      {children}
    </label>
  )
}

export default Section
