import React from 'react'

const ExternalUrlIcon = ({ ...props }) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.3333 13.3333H1.66667V1.66665H3.33333V-1.99328e-05H1.66667C0.741667 -1.99328e-05 0 -0.000118256 0 -0.000118256V14.9999C0 14.9999 0.741667 15 1.66667 15H13.3333C14.25 15 15 15 15 15L14.9999 11.6665H13.3332L13.3333 13.3333ZM9.16667 -1.99328e-05V1.66665H12.1583L3.96667 9.85831L5.14167 11.0333L13.3333 2.84165V5.83331H15V-1.99328e-05H9.16667Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ExternalUrlIcon
