import React from 'react'
import TypeField from '../search/TypeField'
import { useFilters } from './FiltersProvider'

const SubtypeField = ({ availableOptions }) => {
  const { state, changeField } = useFilters()

  return (
    <TypeField
      isMulti={false}
      label="Categoría"
      name="tema"
      placeholder="Todos"
      value={state.context.tema}
      onChange={e => changeField('tema', e)}
      options={availableOptions.themes || []}
      isClearable={false}
      withAllOption
    />
  )
}

export default SubtypeField
