import range from 'lodash/range'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import 'react-dates/initialize'
import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker'
import ArrowDown from '../icons/ArrowDown'
moment.locale('es')

moment.defaultFormat = 'DD-MM-YYYY'

const phrases = {
  closeDatePicker: 'Cerrar',
  focusStartDate: 'Interactúa con el calendario y agrega la fecha de inicio a filtrar.',
  clearDate: 'Limpiar fecha',
  clearDates: 'Limpiar fechas',
  jumpToPrevMonth: 'Mueve hacia atrás para cambiar al mes anterior.',
  jumpToNextMonth: 'Mueve hacia adelante para cambiar al mes siguiente.',
  keyboardShortcuts: 'Combinaciones de teclados.',
  showKeyboardShortcutsPanel: 'Abre el panel de combinaciones de teclado.',
  hideKeyboardShortcutsPanel: 'Cierra el panel de combinaciones.',
  openThisPanel: 'Abrir este panel.',
  enterKey: 'Ingresar tecla.',
  leftArrowRightArrow: 'Fechas derecha e izquierda.',
  upArrowDownArrow: 'Flechas hacia arriba y hacia abajo.',
  pageUpPageDown: 'Teclas página arriba y página abajo.',
  homeEnd: 'Teclas de inicio y fin.',
  escape: 'Tecla escape.',
  questionMark: 'Signo de interrogación.',
  selectFocusedDate: 'Seleccionar fecha enfocada.',
  moveFocusByOneDay: 'Mueve hacia atrás (izquierda) y hacia adelante (derecha) un día.',
  moveFocusByOneWeek: 'Mueve hacia atrás (arriba) y hacia adelante (abajo) una semana.',
  moveFocusByOneMonth: 'Cambiar meses.',
  moveFocustoStartAndEndOfWeek: 'Ir al primer o al último día de una semana.',
  returnFocusToInput: 'Regresar a la fecha de entrada.',
  keyboardNavigationInstructions: `Presiona la flecha hacia abajo para interactuar con el calendario y seleccionar una fecha. 
  Presiona el signo de interrogación final para ver las combinacines de teclado para cambiar fechas.`,
  chooseAvailableStartDate: ({ date }) => `Elige ${date} como fecha de inicio. Está disponible.`,
  chooseAvailableEndDate: ({ date }) => `Elige ${date} como fecha de fin. Está disponible.`,
  chooseAvailableDate: ({ date }) => date,
  dateIsUnavailable: ({ date }) => `No está disponible. ${date}`,
  dateIsSelected: ({ date }) => `Seleccionaste ${date}`,
  dateIsSelectedAsStartDate: ({ date }) => `Seleccionó ${date} como fecha de inicio.`,
  dateIsSelectedAsEndDate: ({ date }) => `Seleccionó ${date} como fecha de fin.`
}

class Daterange extends Component {
  state = {
    focusedStartInput: false,
    focusedEndInput: false
  }

  formatValue = date => (date ? moment(date, 'DD-MM-YYYY') : null)

  getEndCalendar = () => {
    const { onChangeEnd, valueEnd = null, valueStart = null, onFocusCalendar, disabled } = this.props
    return (
      <SingleDatePicker
        date={this.formatValue(valueEnd)}
        onDateChange={endDate => onChangeEnd({ value: endDate && endDate.format('DD-MM-YYYY') })}
        isOutsideRange={day =>
          day.isAfter(moment().endOf('day')) || (day && day.isBefore(this.formatValue(valueStart)))
        }
        focused={this.state.focusedEndInput}
        onFocusChange={({ focused }) => {
          this.setState({ focusedEndInput: focused })
          onFocusCalendar && onFocusCalendar({ value: focused })
        }}
        className="calendar-disabled"
        numberOfMonths={1}
        placeholder="Hasta"
        readOnly
        showClearDate
        hideKeyboardShortcutsPanel
        displayFormat="DD-MM-YYYY"
        dayAriaLabelFormat="DD-MM-YYYY"
        phrases={{ ...phrases, calendarLabel: 'Fecha de fin' }}
        disabled={disabled}
        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <select
                className="date__select date__select-month"
                value={month.month()}
                onChange={e => {
                  onMonthSelect(month, e.target.value)
                }}
              >
                {moment.months().map((label, value) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <select
                className="date__select"
                value={month.year()}
                onChange={e => {
                  onYearSelect(month, e.target.value)
                }}
              >
                {range(50).map(r => (
                  <option key={r} value={moment().year() - r}>
                    {moment().year() - r}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        customInputIcon={<ArrowDown className="inline-block mt-1" />}
      />
    )
  }

  getStartCalendar = () => {
    const { onChangeStart, valueEnd = null, valueStart = null, onFocusCalendar, disabled } = this.props
    return (
      <SingleDatePicker
        date={this.formatValue(valueStart)}
        onDateChange={startDate => onChangeStart({ value: startDate && startDate.format('DD-MM-YYYY') })}
        focused={this.state.focusedStartInput}
        onFocusChange={({ focused }) => {
          this.setState({ focusedStartInput: focused })
          onFocusCalendar && onFocusCalendar({ value: focused })
        }}
        isOutsideRange={day => {
          return day.isAfter(moment()) || day.isAfter(this.formatValue(valueEnd))
        }}
        numberOfMonths={1}
        placeholder="Desde"
        readOnly
        showClearDate
        phrases={{ ...phrases, calendarLabel: 'Fecha de inicio' }}
        hideKeyboardShortcutsPanel
        displayFormat="DD-MM-YYYY"
        dayAriaLabelFormat="DD-MM-YYYY"
        disabled={disabled}
        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <select
                className="date__select date__select-month"
                value={month.month()}
                onChange={e => {
                  onMonthSelect(month, e.target.value)
                }}
              >
                {moment.months().map((label, value) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <select
                className="date__select"
                value={month.year()}
                onChange={e => {
                  onYearSelect(month, e.target.value)
                }}
              >
                {range(50).map(r => (
                  <option key={r} value={moment().year() - r}>
                    {moment().year() - r}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        customInputIcon={<ArrowDown className="inline-block mt-1" />}
      />
    )
  }

  render() {
    const { disabled } = this.props
    return (
      <div className={`flex gap-7 ${disabled ? 'calendar-disabled' : ''}`}>
        <Fragment>
          <div className="flex-1">{this.getStartCalendar()}</div>
          <div className="flex-1">{this.getEndCalendar()}</div>
        </Fragment>
      </div>
    )
  }
}

export default Daterange
