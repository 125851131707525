import React from 'react'
import Select from 'react-select'
import { styles } from '../select/select'
import Section from './Section'
import { getItem } from './utils'
import { formatSelected } from '../institution_searcher/helpers'

const TypeField = ({
  label,
  onChange,
  options,
  placeholder,
  value,
  className,
  disabled,
  variant,
  withAllOption = false,
  isClearable = (value || []).length > 1,
  isMulti = true,
  components
}) => {
  const optionsWithAll = withAllOption ? [{ value: '', label: 'Todos' }, ...options] : options
  return (
    <Section title={label} className={className} variant={variant}>
      <Select
        isMulti={isMulti}
        placeholder={placeholder}
        isClearable={isClearable}
        value={getItem(value, options)}
        options={optionsWithAll}
        onChange={e => onChange(formatSelected(e))}
        styles={styles}
        isDisabled={disabled}
        components={components}
      />
    </Section>
  )
}

export default TypeField
