import React from 'react'

const FullArrowRightIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" {...props}>
      <path d="M16.01 11.5H4V13.5H16.01V16.5L20 12.5L16.01 8.5V11.5Z" fill="currentColor" />
    </svg>
  )
}

export default FullArrowRightIcon
