import React from 'react'
import FilterIcon from '../icons/FilterIcon'
import Button from '../ui/Button'
import FilterCount from './FilterCount'
import { useFilters } from './FiltersProvider'

const FilterButton = () => {
  const { toggleFilters } = useFilters()
  return (
    <div className="md:ml-auto mt-4 md:mt-0 h-full">
      <Button
        id="js-filter-button"
        onClick={toggleFilters}
        className="flex gap-2 items-center w-full md:w-fit justify-center h-full rounded-md"
        variant="secondary"
      >
        <FilterIcon />
        Filtros <FilterCount className="mx-0" />
      </Button>
    </div>
  )
}

export default FilterButton
