import React, { Fragment, useState } from 'react'
import SortField from '../search/SortField'
import NoResults from './NoResults'
import ResultItem from './ResultItem'
import RSSButton from './RSSButton'
import RSSHelper from './RSSHelper'
import LinkDownloadButton from './LinkDownloadButton'

const Results = ({ totalCount, results, currentSearchTerm, institutionReportZipEnabled }) => {
  const [showRSS, setShowRSS] = useState(false)
  return (
    <main className="container md:max-w-6xl m-auto">
      {totalCount > 0 ? (
        <Fragment>
          <div className="py-6 md:py-4 flex flex-wrap gap-4 md:gap-0">
            <h2 className="order-last md:order-first text-xl w-full md:w-fit">
              Resultados ({totalCount.toLocaleString('en')})
            </h2>
            <div className="flex-1 flex flex-wrap items-center md:justify-end justify-between gap-6 md:gap-14">
              <LinkDownloadButton institutionReportZipEnabled={institutionReportZipEnabled} />
              <RSSButton onClick={() => setShowRSS(show => !show)} />
              <SortField />
            </div>
          </div>
          <RSSHelper show={showRSS} />
          <hr className="my-0" />
          <ul className="mb-7">
            {results.filter(Boolean).map(result => (
              <ResultItem key={result.id} item={result} />
            ))}
          </ul>
        </Fragment>
      ) : (
        <NoResults term={currentSearchTerm} />
      )}
    </main>
  )
}

export default Results
