import React from 'react'

const DocumentCollectionTag = ({ item }) => {
  if (item.searchable_type !== 'DocumentsCollection') return null
  return (
    <span className="text-gray-725 border-2 border-blue-200 py-1 px-2 text-xs rounded-md font-medium uppercase leading-none">
      Compendio ({item.collection_count} contenidos)
    </span>
  )
}

export default DocumentCollectionTag
