import React, { Fragment, useContext } from 'react'
import TabItem from '../ui/TabItem'
import Tabs from '../ui/Tabs'
import { FiltersContext } from './FiltersProvider'
import { contentTypes } from './helpers'

const ContentTypesTabs = ({ tabLabelProceduresAndServices }) => {
  const { changeContentTypeFromTabs, state } = useContext(FiltersContext)

  return (
    <Fragment>
      <div className="bg-blue-960 text-gray-200 py-4 md:hidden">
        <label className="flex flex-wrap items-center container md:max-w-6xl">
          <span className="font-medium mr-1">Estás viendo:</span>
          <select
            className="custom-arrow-down-white bg-transparent border-none px-0 w-fit underline flex-1"
            value={state.context.contentType}
            onChange={e => changeContentTypeFromTabs(e.target.value)}
          >
            {contentTypes.map((contentType, index) => (
              <option key={index} value={contentType.value}>
                {contentType.label}
              </option>
            ))}
          </select>
        </label>
      </div>
      <nav className="container md:max-w-6xl hidden md:block">
        <Tabs onChange={value => changeContentTypeFromTabs(value)} value={state.context.contentType}>
          {contentTypes.map((contentType, index) => {
            return (
              <TabItem
                key={index}
                label={contentType.label}
                value={contentType.value}
                tabLabelProceduresAndServices={tabLabelProceduresAndServices}
              />
            )
          })}
        </Tabs>
      </nav>
    </Fragment>
  )
}

export default ContentTypesTabs
