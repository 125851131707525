const arrows = document.querySelectorAll('.js-horizontal-scroll')
let numberViewsScroll = 1

for (let arrow of arrows) {
  arrow.addEventListener('click', () => {
    const direction = arrow.dataset.direction
    const list = direction === 'left' ? arrow.nextSibling : arrow.previousSibling
    list.style['scroll-behavior'] = 'smooth'
    const inset = 64
    const gap = 16
    const cardsToScroll = 3
    const listWidth = list.offsetWidth
    const itemWidth = list.firstElementChild.offsetWidth
    const currentScrollLeft = list.scrollLeft
    const totalItems = list.children.length
    const totalViews = Math.ceil(totalItems / cardsToScroll)
    const scrollWidthMax = listWidth
    const currentView = Math.round(currentScrollLeft / itemWidth)

    if (direction === 'left') {
      const scrollWidthNext = currentScrollLeft - (itemWidth * cardsToScroll + gap * (cardsToScroll - 1)) - 16
      const rightArrow = arrow.nextSibling.nextSibling
      rightArrow.classList.remove('md:hidden')
      numberViewsScroll--
      if (scrollWidthNext <= 1 || numberViewsScroll == 1) {
        list.scrollLeft = 0
        arrow.classList.add('hidden')
      } else {
        const elementToScroll = list.querySelector(`.js-scroll-${currentView - cardsToScroll}`)
        list.scrollLeft = elementToScroll.offsetLeft - arrow.clientWidth + inset
      }
    } else {
      const scrollWidthNext = currentScrollLeft + itemWidth * cardsToScroll + gap * (cardsToScroll - 1)
      const leftArrow = arrow.previousSibling.previousSibling
      leftArrow.classList.remove('hidden')
      numberViewsScroll++
      if (scrollWidthNext >= scrollWidthMax || numberViewsScroll == totalViews) {
        list.scrollLeft = list.scrollWidth
        arrow.classList.add('md:hidden')
      } else {
        const elementToScroll = list.querySelector(`.js-scroll-${currentView + cardsToScroll}`)
        list.scrollLeft = elementToScroll.offsetLeft - arrow.clientWidth + inset
      }
    }
  })
}
