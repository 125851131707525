import React, { Component } from 'react'
import Select from 'react-select'

export const styles = {
  control: (base, state) => ({
    ...base,
    borderRadius: 0,
    border: state.isDisabled ? '2px solid #C5CDDA' : '2px solid #000',
    backgroundColor: state.isDisabled ? '#F6F9FC' : '#fff',
    boxShadow: '0 !important',
    '&:hover': {
      borderBottom: '2px solid #000'
    }
  }),
  placeholder: (base, state) => ({
    ...base,
    color: state.isDisabled ? '#8A94A2' : '#26292E'
  }),
  indicatorSeparator: (base, state) => ({
    display: 'none'
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isDisabled ? '#8A94A2' : '#26292E'
  }),
  container: (base, state) => ({
    ...base,
    flex: 1,
    '&:focus-within': {
      outline: '3px solid #FFD147',
      outlineOffset: 0
    }
  }),
  multiValueLabel: (styles, { data }) => ({
    whiteSpace: 'inherit'
  }),
  option: (provided, state) => ({
    ...provided,
    color: '#333',
    fontSize: '16px',
    background: state.isSelected ? '#e5e5e5' : state.isFocused ? '#f2f2f2' : '#fff',
    '&:active': {
      background: '#f2f2f2'
    }
  })
}

class ListToDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = { value: null }
    this.onChange = this.onChange.bind(this)
  }

  onChange(value) {
    this.setState({ value })
  }

  render() {
    const { list } = this.props

    let addPhoneNumber

    if (this.state.value)
      addPhoneNumber = (
        <div className="form-field-phone mt-6" dangerouslySetInnerHTML={{ __html: this.state.value.markup }} />
      )

    return (
      <div>
        <Select
          name="form-field-name"
          placeholder="Selecciona una opción"
          value={this.state.value}
          options={list}
          onChange={this.onChange}
          styles={styles}
        />
        <div>{addPhoneNumber}</div>
      </div>
    )
  }
}

export default ListToDropdown

//<a href={`tel:${this.state.value.phone}`}  className='form-field-phone'>
//Click: {this.state.value.phone}
//</a>
