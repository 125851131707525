import React from 'react'

const Person = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
      <path
        d="M32 32C40.8366 32 48 24.8366 48 16C48 7.16344 40.8366 0 32 0C23.1634 0 16 7.16344 16 16C16 24.8366 23.1634 32 32 32Z"
        fill="#C5CDDA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 36C21.32 36 0 41.36 0 52V64H64V52C64 41.36 42.68 36 32 36Z"
        fill="#C5CDDA"
      />
    </svg>
  )
}

export default Person
