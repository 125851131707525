export const contentTypes = [
  {
    label: 'Todos',
    value: 'todos'
  },
  {
    value: 'servicios',
    label: 'Orientación, trámites y servicios'
  },
  {
    value: 'normas',
    label: 'Normas y documentos legales'
  },
  {
    value: 'publicaciones',
    label: 'Informes y publicaciones'
  },
  { value: 'noticias', label: 'Noticias' },
  { value: 'campañas', label: 'Campañas y eventos' },
  { value: 'institucional-completo', label: 'Información institucional' }
]

export const subtypesMap = {
  servicios: null,
  noticias: 'tipo_noticia',
  normas: 'tipo_norma',
  publicaciones: 'tipo_publicacion',
  campañas: null,
  'institucional-completo': 'tipo_institucional'
}

export const apiToKeysMap = {
  tipo_noticia: 'feed_types',
  tipo_norma: 'group_rule_types',
  tipo_publicacion: 'group_report_types',
  tipo_institucional: 'institutional_types',
  tema: 'themes'
}

export const formatSelected = selected => {
  if (!selected) return selected
  if (selected.constructor === Array) {
    return selected.map(s => s.value)
  } else {
    return selected.value
  }
}

export const searchableTypeMap = {
  Institution: 'Portales institucionales',
  Campaign: 'Campañas',
  Feed: 'Noticias',
  Official: 'Directorio de funcionarios',
  Report: 'Informes y publicaciones',
  Rule: 'Normas y documentos legales',
  Page: 'Orientación, trámites y servicios',
  BranchOffice: 'Sedes'
}

export const pageKindMap = {
  procedure: 'Trámite',
  service: 'Servicio',
  institutional: 'Contenido institucional'
}

export const getFriendlySearchType = ({ item }) => {
  const searchableTypeField = item.searchable_type === 'FakeSearchDocument' ? item.fake_type : item.searchable_type
  return item.page_kind === 'institutional'
    ? pageKindMap[item.page_kind]
    : searchableTypeMap[searchableTypeField] || searchableTypeMap[item.official_document_type]
}

export const contentHasPublicationDate = contentType => {
  return (
    contentType === 'noticias' ||
    contentType === 'normas' ||
    contentType === 'publicaciones' ||
    contentType === 'campañas'
  )
}

export const getDefaultsFromQuery = query => {
  const { contenido, tipo_noticia, tipo_norma, tipo_publicacion, term, desde, hasta, sort_by, sheet, tema } = query
  const defaults = {
    contentType: contenido || 'todos',
    tipo_noticia: tipo_noticia || '',
    tipo_norma: tipo_norma || '',
    tipo_publicacion: tipo_publicacion || '',
    term: term || '',
    desde: desde || '',
    hasta: hasta || '',
    sort_by: sort_by || 'none',
    tema: tema || '',
    sheet: sheet || 1
  }
  return {
    ...defaults,
    oldValues: defaults
  }
}

export const copyToClipboard = (e, ref) => {
  ref.current.select()
  document.execCommand('copy')
  e.target.focus()
}

export const sortableContentTypes = ['normas', 'campañas', 'noticias', 'publicaciones']
