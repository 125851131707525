import React from 'react'

const RSSIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
      <path
        d="M6.4525 23.905C7.80698 23.905 8.905 22.807 8.905 21.4525C8.905 20.098 7.80698 19 6.4525 19C5.09802 19 4 20.098 4 21.4525C4 22.807 5.09802 23.905 6.4525 23.905Z"
        fill="black"
      />
      <path
        d="M4.5 6.11914V9.30289C12.4088 9.30289 18.8212 15.7154 18.8212 23.6241H22.005C22.005 13.9604 14.1637 6.11914 4.5 6.11914ZM4.5 12.4866V15.6704C8.8875 15.6704 12.4537 19.2366 12.4537 23.6241H15.6375C15.6375 17.4704 10.6537 12.4866 4.5 12.4866Z"
        fill="black"
      />
    </svg>
  )
}

export default RSSIcon
