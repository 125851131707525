import React from 'react'
import ExternalUrlIcon from '../icons/ExternalUrlIcon'
import { useFilters } from './FiltersProvider'

const GoogleSearchSuggestion = () => {
  const { term } = useFilters()
  if (!term) return null
  return (
    <div className="container px-4 md:max-w-6xl">
      <div className="bg-gray-40 py-6 flex justify-center ">
        <a className="underline" href={`https://www.google.com/search?q=${encodeURI(term)}`} target="_blank">
          Buscar <span className="font-bold">{term}</span> en Google
          <ExternalUrlIcon className="inline no-underline ml-2" />
        </a>
      </div>
    </div>
  )
}

export default GoogleSearchSuggestion
