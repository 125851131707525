import { useMachine } from '@xstate/react'
import React, { Fragment, useEffect, useRef } from 'react'
import { createMachine } from 'xstate'
import ArrowRight from '../icons/ArrowRight'
import HelpIcon from '../icons/HelpIcon'

const feedbackMachine = createMachine({
  initial: 'closed',
  id: 'feedback',
  states: {
    closed: {
      on: {
        OPEN: 'opened'
      }
    },
    opened: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            SUBMIT: [{ target: 'invalid', cond: 'noComment' }, 'sending'],
            CLOSE: '#feedback.closed'
          }
        },
        sending: {
          invoke: {
            src: 'createFeedback',
            onDone: 'success',
            onError: '#feedback.closed'
          }
        },
        invalid: {
          on: {
            SUBMIT: [{ target: 'invalid', cond: 'noComment' }, 'sending']
          }
        },
        success: { type: 'final' }
      }
    }
  }
})

const FeedbackButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className=" text-lg bg-white py-2 px-3 md:py-6 md:px-9 font-bold flex justify-between items-center w-full"
    >
      Reportar falta de información
      <ArrowRight className="transform rotate-90 ml-16" />
    </button>
  )
}

const SearchFeedback = ({ searchId, openAndScroll, setOpenAndScroll }) => {
  const commentRef = useRef(null)
  const nameRef = useRef(null)
  const containerRef = useRef(null)
  const [state, send] = useMachine(
    feedbackMachine.withConfig({
      services: {
        createFeedback: (ctx, evt) =>
          fetch(`/busquedas/${searchId}/feedbacks.json`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              feedback: {
                name: nameRef.current.value,
                good: false,
                reason_id: 0,
                comment: commentRef.current.value
              }
            })
          })
      },
      guards: {
        noComment: () => !commentRef.current.value
      }
    })
  )

  useEffect(() => {
    if (openAndScroll) {
      send('OPEN')
      containerRef.current.scrollIntoView({ behavior: 'smooth' })
      setOpenAndScroll(false)
    }
  }, [openAndScroll])

  return (
    <section
      ref={containerRef}
      className="bg-gray-40 md:pt-12 md:pb-18 pt-6 pb-8 flex flex-col items-center mt-9 px-4 md:px-0"
    >
      <HelpIcon />
      <h3 className="font-bold text-2xl mt-4 leading-normal text-center mb-8">¿Necesitas ayuda con tu búsqueda?</h3>

      <div className="border-2 border-green-400 w-full max-w-md bg-white">
        {state.matches('closed') && <FeedbackButton onClick={() => send('OPEN')} />}

        {(state.matches('opened.idle') || state.matches('opened.invalid') || state.matches('opened.sending')) && (
          <Fragment>
            <FeedbackButton onClick={() => send('CLOSE')} />
            <div className="p-9 border-t-2 border-green-500">
              <form
                onSubmit={e => {
                  e.preventDefault()
                  send('SUBMIT')
                }}
              >
                <div className="w-0 h-0 overflow-hidden">
                  <label>
                    <input name="feedback[name]" autoComplete="off" ref={nameRef} />
                  </label>
                </div>
                <label className="mb-2" htmlFor="search_feedback_textarea">
                  Cuéntanos qué estabas buscando
                </label>
                <textarea
                  id="search_feedback_textarea"
                  className={'w-full border-2 ' + (state.matches('opened.invalid') ? 'border-red-500' : 'border-black')}
                  placeholder="Me gustaría encontrar..."
                  rows={4}
                  ref={commentRef}
                />
                {state.matches('opened.invalid') && (
                  <span className="text-red-500 text-sm block mb-4">Este campo es requerido</span>
                )}
                <button type="submit" className="bg-green-500 text-white py-2 mt-4 w-full px-12 font-bold">
                  Enviar
                </button>
              </form>
            </div>
          </Fragment>
        )}
        {state.matches('opened.success') && <p className="py-4 px-8 font-bold mb-0">Muchas gracias</p>}
      </div>
    </section>
  )
}

export default SearchFeedback
