import { useMachine } from '@xstate/react'
import pickBy from 'lodash/pickBy'
import qs from 'query-string'
import React from 'react'
import { actions, assign, Machine, send } from 'xstate'
import ContentTypesTabs from './ContentTypesTabs'
import Filters from './Filters'
import FilterButton from './FilterButton'
import FiltersProvider from './FiltersProvider'
import Results from './Results'
import TermField from './TermField'
import CurrentFilters from './CurrentFilters'
import Help from './Help'
import Pagination from './Pagination'
import GoogleSearchSuggestion from './GoogleSearchSuggestion'
import SearchFeedback from './SearchFeedback'

const searchMachine = Machine({
  id: 'search',
  initial: 'idle',
  states: {
    idle: {},
    searching: {
      after: {
        1000: {
          actions: 'showLoading'
        }
      },
      invoke: {
        src: ctx =>
          fetch(`/busquedas.json?${ctx.queryString}`)
            .then(d => d.json())
            .then(d => d.data),
        onDone: {
          target: 'idle',
          actions: ['saveCurrentResultsTerm', 'saveFetch']
        },
        onError: 'error'
      },
      exit: 'hideLoading'
    },
    error: {}
  },
  on: {
    ACTUAL_SEARCH: {
      target: '.searching',
      internal: false,
      actions: ['saveCurrentAskedTerm', 'queryfy', 'changeQueryPath']
    },
    SEARCH: {
      actions: [
        actions.cancel('search-debounce'),
        send(
          (_, evt) => {
            return { type: 'ACTUAL_SEARCH', values: evt.values }
          },
          { delay: 1000, id: 'search-debounce' }
        )
      ]
    }
  }
})

const InstitutionSearcher = ({
  initialData,
  institutionName,
  institutionSlug,
  institutionReportZipEnabled,
  tabLabelProceduresAndServices
}) => {
  const [state, send] = useMachine(
    searchMachine
      .withContext({
        searchId: initialData?.data?.id,
        results: initialData?.data?.attributes?.results,
        filterOptions: initialData?.data?.attributes?.filters,
        totalCount: initialData?.data?.attributes?.total_count,
        counts: initialData?.data?.attributes?.filters?.content_types,
        suggestions: initialData?.data?.attributes?.suggestions?.[1],
        suggestions_text: initialData?.data?.attributes?.suggestions_text?.[0],
        institutionSlug: institutionSlug,
        queryString: '',
        loading: false,
        calendarFocused: false,
        currentResultsTerm: qs.parse(location.search || {})?.term,
        currentAskedTerm: qs.parse(location.search || {})?.term
      })
      .withConfig({
        actions: {
          saveCurrentResultsTerm: assign(ctx => ({ currentResultsTerm: ctx.currentAskedTerm })),
          saveCurrentAskedTerm: assign((_, evt) => ({
            currentAskedTerm: evt.values.term
          })),
          changeQueryPath: ctx => {
            window.history.replaceState({ turbolinks: {} }, '', `${window.location.pathname}?${ctx.queryString}`)
          },
          saveFetch: assign((_, evt) => ({
            searchId: evt,
            results: evt.data.attributes.results,
            filterOptions: evt.data.attributes.filters,
            totalCount: evt.data.attributes.total_count,
            suggestions: evt.data.attributes.suggestions?.[1],
            suggestions_text: evt.data.attributes.suggestions_text?.[0],
            counts: evt.data?.attributes?.filters?.content_types
          })),
          queryfy: assign((_, evt) => {
            const { values } = evt
            const queryString = qs.stringify(
              pickBy(
                {
                  contenido: values.contenido,
                  desde: values.desde,
                  hasta: values.hasta,
                  institucion: institutionSlug,
                  term: values.term,
                  tema: values.tema,
                  categoria: values.categoria,
                  tipo_noticia: values.tipo_noticia,
                  tipo_norma: values.tipo_norma,
                  tipo_publicacion: values.tipo_publicacion,
                  tipo_segmento: values.tipo_segmento,
                  tipo_institucional: values.tipo_institucional,
                  sort_by: values.sort_by,
                  sheet: values.sheet
                },
                Boolean
              ),
              {
                arrayFormat: 'bracket'
              }
            )
            return {
              queryString
            }
          }),
          showLoading: assign(() => ({
            loading: true
          })),
          hideLoading: assign(() => ({
            loading: false
          }))
        }
      })
  )
  const [openAndScrollSearchFeedback, setOpenAndScrollSearchFeedback] = React.useState(false)

  return (
    <FiltersProvider
      onSubmit={values => send('SEARCH', { values })}
      onInstaSubmit={values => {
        send('ACTUAL_SEARCH', { values })
        window.scrollTo(0, 0)
      }}
    >
      <header className="bg-gray-100 md:mb-2">
        <div className="container md:max-w-6xl py-6 grid gap-4 md:grid-cols-3/4 items-center">
          <h1 className="text-3xl mb-0" dangerouslySetInnerHTML={{ __html: `Buscando en ${institutionName}` }} />
          <Help setOpenAndScrollSearchFeedback={setOpenAndScrollSearchFeedback} />
          <TermField />
          <FilterButton />
        </div>
      </header>
      <Filters availableOptions={state.context.filterOptions} />
      <ContentTypesTabs tabLabelProceduresAndServices={tabLabelProceduresAndServices} />
      <CurrentFilters availableOptions={state.context.filterOptions} />
      <Results
        error={state.matches('error')}
        totalCount={state.context.totalCount}
        results={state.context.results}
        searchId={state.context.searchId}
        loading={state.context.loading}
        currentSearchTerm={state.context.currentResultsTerm}
        institutionReportZipEnabled={institutionReportZipEnabled}
      />
      <nav aria-label="Páginación" className="search-pagination">
        {!state.matches('error') && <Pagination totalCount={state.context.totalCount} />}
      </nav>
      <GoogleSearchSuggestion />
      <SearchFeedback
        searchId={state.context.searchId}
        openAndScroll={openAndScrollSearchFeedback}
        setOpenAndScroll={setOpenAndScrollSearchFeedback}
      />
    </FiltersProvider>
  )
}

export default InstitutionSearcher
