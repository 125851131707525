import { useMachine } from '@xstate/react'
import React, { Fragment, useRef } from 'react'
import { Machine } from 'xstate'
import ArrowRight from '../icons/ArrowRight'

const feedbackMachine = Machine({
  initial: 'closed',
  id: 'feedback',
  states: {
    closed: {
      on: {
        OPEN: 'opened'
      }
    },
    opened: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            SUBMIT: [{ target: 'invalid', cond: 'noComment' }, 'sending'],
            CLOSE: '#feedback.closed'
          }
        },
        sending: {
          invoke: {
            src: 'createFeedback',
            onDone: 'success',
            onError: '#feedback.closed'
          }
        },
        invalid: {
          on: {
            SUBMIT: [{ target: 'invalid', cond: 'noComment' }, 'sending']
          }
        },
        success: { type: 'final' }
      }
    }
  }
})

const SearchFeedback = ({
  searchId,
  label = '¿No encontraste lo que buscabas?',
  widthClass = 'w-full md:w-1/3 m-auto'
}) => {
  const commentRef = useRef(null)
  const nameRef = useRef(null)
  const [state, send] = useMachine(
    feedbackMachine.withConfig({
      services: {
        createFeedback: (ctx, evt) =>
          fetch(`/busquedas/${searchId}/feedbacks.json`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              feedback: {
                name: nameRef.current.value,
                good: false,
                reason_id: 0,
                comment: commentRef.current.value
              }
            })
          })
      },
      guards: {
        noComment: () => !commentRef.current.value
      }
    })
  )

  if (!searchId) return null

  return (
    <div className="flex">
      <div className={'bg-purple-150 ' + widthClass}>
        {state.matches('closed') && (
          <div className="py-4 px-8">
            <button
              onClick={() => send('OPEN')}
              className="text-indigo-500 font-bold flex justify-between items-center w-full"
            >
              {label}
              <ArrowRight className="transform rotate-90 ml-16" />
            </button>
          </div>
        )}
        {(state.matches('opened.idle') || state.matches('opened.invalid') || state.matches('opened.sending')) && (
          <Fragment>
            <div className="pt-4 px-8">
              <button
                onClick={() => send('CLOSE')}
                className="text-indigo-500 font-bold flex justify-between items-center w-full"
              >
                {label}
                <ArrowRight className="transform rotate-90 ml-16" />
              </button>
            </div>
            <hr aria-hidden="true" />
            <div className="pb-4 px-8">
              <form
                onSubmit={e => {
                  e.preventDefault()
                  send('SUBMIT')
                }}
              >
                <div className="w-0 h-0 overflow-hidden">
                  <label>
                    <input name="feedback[name]" autoComplete="off" ref={nameRef} />
                  </label>
                </div>
                <label className="mb-2" htmlFor="search_feedback_textarea">
                  Cuéntanos qué estabas buscando
                </label>
                <textarea
                  id="search_feedback_textarea"
                  className={state.matches('opened.invalid') ? 'border-red-500' : 'border-none'}
                  placeholder="Me gustaría encontrar"
                  rows={4}
                  ref={commentRef}
                />
                {state.matches('opened.invalid') && (
                  <span className="text-red-500 text-sm block mb-4">Este campo es requerido</span>
                )}
                <button type="submit" className="bg-green-500 text-white py-2 px-12 font-bold">
                  Enviar
                </button>
              </form>
            </div>
          </Fragment>
        )}
        {state.matches('opened.success') && <p className="text-indigo-500 py-4 px-8 font-bold mb-0">Muchas gracias</p>}
      </div>
    </div>
  )
}

export default SearchFeedback
