import React from 'react'
import { useFilters } from './FiltersProvider'
import { isOrderSafe } from './utils'

export const SorterField = () => {
  const { state, send } = useFilters()
  const isMobile = state.matches('mobile')
  const options = [
    { value: 'none', label: 'Más relevante' },
    { value: 'recent', label: 'Más reciente' },
    { value: 'older', label: 'Más antiguo' }
  ]
  const orderIsDisabled = !isOrderSafe(state.context.values)

  return (
    <div className="flex items-baseline md:mb-0 mb-4">
      <label aria-labelledby="search_sort_by_selector" className="font-bold mr-2 md:w-1/2">
        Ordenar:
      </label>
      <select
        id="search_sort_by_selector"
        value={state.context.values.sort_by}
        disabled={orderIsDisabled}
        onChange={e =>
          send('CHANGE_SUBMIT', {
            key: 'sort_by',
            value: e.target.value
          })
        }
        className={`border-2 border-black py-1 px-2 bg-white rounded-none focus:rounded-none md:w-48 ${
          isMobile ? 'flex-1' : ''
        } ${orderIsDisabled ? 'bg-gray-100' : ''}`}
      >
        {options.map(o => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
    </div>
  )
}
