import React from 'react'
import SearchIcon from '../icons/SearchIcon'
import { useFilters } from './FiltersProvider'

const TermField = () => {
  const { state, changeTerm, instaSubmit } = useFilters()
  const handleSubmit = e => {
    e.preventDefault()
    instaSubmit()
  }
  return (
    <form onSubmit={handleSubmit} className="institution-search-input__container relative">
      <input
        className="w-full border-2 border-blue-200 bg-white text-xl py-2 pr-18 search-input institution-search-input relative"
        autoFocus
        value={state.context.term}
        onChange={e => changeTerm(e.target.value)}
      />
      <button type="submit" className="absolute right-2 py-1 top-2 px-2">
        <SearchIcon width="24px" height="24px" />
      </button>
    </form>
  )
}

export default TermField
