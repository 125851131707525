import React from 'react'
import { createElementFromHTML } from '../helpers'
import ArrowRight from '../icons/ArrowRight'
import { getFriendlySearchType } from '../institution_searcher/helpers'
import ActionLink, { confirmActionDialog } from './ActionLink'
import BelongsToCollection from './BelongsToCollection'
import PageChildren from './PageChildren'
import PageDisabled from './PageDisabled'

const searchable_type_map = {
  Institution: 'Portales institucionales',
  Campaign: 'Campañas',
  Feed: 'Noticias',
  Official: 'Directorio de funcionarios',
  Report: 'Informes y publicaciones',
  Rule: 'Normas y documentos legales',
  Page: 'Orientación, trámites y servicios',
  BranchOffice: 'Contenido institucional'
}

const button_label_map = {
  Institution: 'Ir al portal',
  BranchOffice: 'Ver horario de atención'
}

const Result = ({ item }) => {
  const seeMore = createElementFromHTML(item.url)
  const seeMoreClass = seeMore.getAttribute('class')
  const seeMoreHref = seeMore.getAttribute('href')
  const seeMoreDataOrigin = seeMore.getAttribute('data-origin')

  const friendlySearchType = getFriendlySearchType({ item })

  return (
    <li>
      <article aria-labelledby={`result-title-${item.id}-label`} className="bg-blue-300 mt-4 p-8">
        <div className="flex justify-between flex-wrap items-baseline">
          <p className="mb-0 font-bold text-base whitespace-pre">{friendlySearchType}</p>
          {item.publication && <time className="text-sm text-gray-900">Publicado: {item.publication}</time>}
        </div>
        <hr aria-hidden="true" />
        <div className="row">
          <div className="col-md-9">
            {item.subject && <p className="text-gray-500 mb-2">{item.subject}</p>}
            <div className="mb-2">
              <h3
                id={`result-title-${item.id}-label`}
                className="text-2xl font-bold inline"
                dangerouslySetInnerHTML={{ __html: item.url }}
                aria-describedby={`result-title-${item.id}-description`}
                onClick={confirmActionDialog}
              />
              {item.official_document_type && (
                <span className="bg-white text-gray-500 p-1 text-sm ml-2 font-medium">Compendio</span>
              )}
            </div>

            {item.content_sub_title_card && <h4 className="text-base mb-4">{item.content_sub_title_card}</h4>}

            {item.official ? (
              <dl>
                {item.official.position && (
                  <div className="flex">
                    <dt className="mr-2">Cargo: </dt>
                    <dd>{item.official.position}</dd>
                  </div>
                )}
                {item.official.started_at && (
                  <div className="flex">
                    <dt className="mr-2">Inicio: </dt>
                    <dd>{item.official.started_at}</dd>
                  </div>
                )}
                {item.official.email && (
                  <div className="flex">
                    <dt className="mr-2">Correo: </dt>
                    <dd>
                      <a href={`mailto:${item.official.email}`}>{item.official.email}</a>
                    </dd>
                  </div>
                )}
              </dl>
            ) : (
              <p
                id={`result-title-${item.id}-description`}
                className="mb-0"
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            )}
            {item.has_accessibility_video && (
              <p className="mt-4 mb-0 text-red-700 font-normal">Contiene video de lenguaje de señas</p>
            )}
            <BelongsToCollection item={item} />
            <PageChildren item={item} />
          </div>
          <div className="col-md-3">
            {item.image_url && (
              <a href={seeMoreHref} className="track-ga-click" data-origin={`result-photo-${item.id}`}>
                <img src={item.image_url} className="w-11/12 h-auto mb-6 ml-auto" alt="" />
              </a>
            )}
          </div>
        </div>
        {item.expired_campaign ? <span className="deadline_date mt-4 inline-block">Campaña culminada</span> : null}
        <div className="flex justify-between mt-4">
          {item.disabled ? <PageDisabled item={item} /> : <ActionLink item={item} />}
          <a
            className={`${seeMoreClass} flex items-center font-bold ml-auto`}
            href={seeMoreHref}
            onClick={confirmActionDialog}
            data-origin={seeMoreDataOrigin}
            aria-hidden="true"
            tabIndex="-1"
          >
            {button_label_map[item.searchable_type] || 'Ver más'}
            <span className="ml-4">
              <ArrowRight className="inline" />
            </span>
          </a>
        </div>
      </article>
    </li>
  )
}

export default Result
