import React from 'react'

const ArrowRight = ({ ...props }) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 10.1 16.3"
      width="10.1"
      height="16.3"
      {...props}
    >
      <path fill="currentColor" d="M0,14.4l6.2-6.2L0,1.9L2,0l8.1,8.1L2,16.3L0,14.4z" />
    </svg>
  )
}

export default ArrowRight
