import React from 'react'

const PageChildren = ({ item }) => {
  return (
    item.searchable_type === 'Page' &&
    item.children_pages?.length > 0 && (
      <ul className="grid grid-cols-2 gap-4 mt-4 leading-tight pl-4" arial-label="Páginas hijas">
        {item.children_pages.map((page, index) => (
          <li key={index} className="list-disc list-outside text-blue-700">
            <a className="underline font-medium" href={page.path}>
              {page.name}
            </a>
          </li>
        ))}
      </ul>
    )
  )
}

export default PageChildren
